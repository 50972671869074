import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {map} from "rxjs/operators";
import {CREATE, OrderStore} from "../order-overview/order.store";

@Injectable()
export class ShipmentService {
    shipmentUrl = environment.shippingService.shipUri;
    constructor(private httpClient:HttpClient, private orderStore:OrderStore) {
    }

    ship(shipment:any):Observable<any>{
        console.log("DOING SHIPMENT: ", shipment);
        return this.httpClient.post(this.shipmentUrl,shipment).pipe(map(value => {
            this.orderStore.dispatch({type:CREATE, data:value})
            return value;
        }));
    }
}
