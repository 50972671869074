import {BehaviorSubject} from "rxjs";
import {Order} from "../model/order-model";
import {Injectable} from "@angular/core";

export const GET = 1;
export const CREATE = 2;
@Injectable()
export class OrderStore {
    searchData: Order;

    item: BehaviorSubject<Order> = new BehaviorSubject<Order>(null);

    constructor() {
    }

    dispatch(action) {
        this.searchData = this.reduce(action);
        this.item.next(this.searchData);
    }

    reduce(action) {
        switch (action.type) {

            case GET:
                return this.searchData;
            default:
                return this.searchData = action.data;
        }
    }
}
