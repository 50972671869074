<html>
    <body>
    <p-toast position="top-center" key="alreadyStorno"></p-toast>
    <p-toast position="top-center" key="stornoSuccess"></p-toast>
    <p-toast position="top-center" key="stornoWarning"></p-toast>
    <p-toast position="top-center" key="stornoError"></p-toast>
    <p-toast position="top-center" key="labelSuccess"></p-toast>
    <p-toast position="top-center" key="labelWarning"></p-toast>
    <p-toast position="top-center" key="labelError"></p-toast>

    <div style="text-align: center">
        <p-confirmDialog #stornoDialog appendTo="body" key="storno" [style]="{width: '50vw'}">
            <p-footer>
                <button type="button" pButton icon="pi pi-check" label="Ja" (click)="stornoDialog.accept()"></button>
                <button type="button" pButton icon="pi pi-times" label="Abbrechen" (click)="stornoDialog.reject()"></button>
            </p-footer>
        </p-confirmDialog>
    </div>

    <div style="text-align: center">
        <p-confirmDialog #labelDialog appendTo="body" key="labelPrint" [style]="{width: '50vw'}">
            <p-footer>
                <button type="button" pButton icon="pi pi-check" label="Ok" (click)="labelDialog.accept()"></button>
                <button type="button" pButton icon="pi pi-times" label="Abbrechen" (click)="labelDialog.reject()"></button>
            </p-footer>
        </p-confirmDialog>
    </div>

    <div style="text-align: center">
        <p-confirmDialog #forceStorno appendTo="body" key="stornoForce" [style]="{width: '50vw'}">
            <p-footer>
                <button type="button" pButton icon="pi pi-check" label="Ok" (click)="forceStorno.accept()"></button>
                <button type="button" pButton icon="pi pi-times" label="Abbrechen" (click)="forceStorno.reject()"></button>
            </p-footer>
        </p-confirmDialog>
    </div>

    <div>
        <p-progressSpinner class="spinnerLayout" *ngIf="loading"></p-progressSpinner>
    </div>



    <div class="sub">
        <div class="ui-g-12">
            <div style="text-align: -webkit-left;" >
                <h2>
                    Auftragsübersicht<br>
                </h2>
            </div>

            <div style="text-align: -webkit-right;">
                <button id="searcher" pButton pRipple label="{{searchtext}}" icon="pi pi-search" class="ui-button-rounded" (click)="activateSearch()"></button>
            </div>
            <div *ngIf="suche" style="text-align: -webkit-right;margin-top: 1%">
                <button pButton pRipple label="Filter zurücksetzen" icon="pi pi-undo" class="ui-button-rounded" (click)="resetSearch()"></button>
            </div>
        </div><br />




        <div class="ui-g-12" style="padding: 0">

            <p-table  styleClass="p-datatable-gridlines" class="ui-g-12" #dt [columns]="cols" [value]="sendungen" selectionMode="single" [(selection)]="selectedSendung" dataKey="shipTimestamp" lazy="true"
                     (onLazyLoad)="loadLazy($event)" [loading]="lazyLoading" [totalRecords]="totalCount" [paginator]="true" [rows]="rows" [rowsPerPageOptions]="rowsPerPageOptions"
                     [showCurrentPageReport]="true" currentPageReportTemplate="Auftrag {first} bis {last} - Gesamt: {totalRecords}">

                <ng-template  pTemplate="header" style="text-align: left">
                    <tr *ngIf="suche">
                        <th *ngFor="let col of cols" [ngSwitch]="col.field" class="ui-fluid">
                            <div *ngIf="col.field == 'state'">
                                <input (click)="$event.stopPropagation()"  (mousedown)="$event.target.value=''" *ngSwitchCase="col.field" style="width: 100%" pInputText type="text" [placeholder]="col.header" (change)="ownFilter($event.target.value, col.field, 'contains')" pTooltip="Insert 'ordered' or 'canceled'">
                            </div>
                            <div *ngIf="col.field != 'state'">
                                <input  (mousedown)="$event.target.value=''" *ngSwitchCase="col.field" style="width: 100%" pInputText type="text" [placeholder]="col.header" (change)="ownFilter($event.target.value, col.field, 'contains')">
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of cols" [pSortableColumn]="col.field" pResizableColumn pReorderableColumn>
                            <div style="text-align: -webkit-center;">
                                <span>{{col.header}}</span>
                                <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order" ariaLabel></p-sortIcon>
                            </div>

                        </th>
                        <th colspan="1"></th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-expanded="expanded" let-index="index" let-columns="columns">
                    <tr>
                        <td style="overflow: hidden;text-align: -webkit-center;">
                            <span *ngIf="rowData.state == 'ORDERED'" class="p-tag p-tag-success">ORDERED</span>
                            <span *ngIf="rowData.state == 'CANCELED'" class="p-tag p-tag-danger">CANCELED</span>
                        </td>
                        <td style="overflow: hidden;text-align: -webkit-center;">{{rowData.shipmentId}}</td>
                        <td style="overflow: hidden;text-align: -webkit-center;"><b>{{rowData.shipTimestamp}}</b></td>
                        <td style="overflow: hidden;text-align: -webkit-center;">{{rowData.shipFromPersonName}}</td>
                        <td style="overflow: hidden;text-align: -webkit-center;">{{rowData.shipFromStreet + ' '+ rowData.shipFromHouseNr}}</td>
                        <td style="overflow: hidden;text-align: -webkit-center;">{{rowData.shipFromCity + ", \n" + rowData.shipFromZipCode}}</td>
                        <td style="overflow: hidden;text-align: -webkit-center;">{{rowData.recipientPersonName}}</td>
                        <td style="overflow: hidden;text-align: -webkit-center;">{{rowData.recipientStreet + ' ' + rowData.recipientHouseNr}}</td>
                        <td style="overflow: hidden;text-align: -webkit-center;">{{rowData.recipientCity+ ", \n" + rowData.recipientZipCode}}</td>
                        <td style="overflow: hidden;text-align: -webkit-center;"><img style="max-width: -webkit-fill-available; height: auto" [attr.alt]="rowData.carrier" [src]="findCarrierPictureForOverview(rowData.carrier)"></td>
                        <td style="overflow: hidden;text-align: -webkit-center;"><b>{{rowData.deliveryDate}}</b></td>
                        <td style="overflow: hidden;text-align: -webkit-center;">{{rowData.charge + '&nbsp;' + rowData.currency}}</td>
                        <td style="overflow: hidden;text-align: -webkit-center;">{{'&nbsp;'}}

                                <button *ngIf="!rowData.canceled && !rowData.inThePast" style="margin-right: 1%" pButton pRipple type="button" id="printButton" (click)="labelConfirm(rowData.trackingNumber)" class="p-button-info showTooltip" icon="pi pi-print"
                                        [disabled]="rowData.canceled || rowData.inThePast"></button>


                            <button *ngIf="!rowData.canceled && !rowData.inThePast"  pButton pRipple type="button" id="stornoButton" (click)="stornoConfirm(rowData.shipmentId, rowData.carrier, rowData.state)"
                                    class="p-button-danger" icon="pi pi-times" [disabled]="rowData.canceled || rowData.inThePast"></button>

                            <div pTooltip="Dieser Autrag kann nicht gedruckt/storniert werden.">
                            <button *ngIf="rowData.canceled || rowData.inThePast" style="margin-right: 1%" pButton pRipple type="button"
                                   id="disabledPrintButton" (click)="labelConfirm(rowData.trackingNumber)" class="p-button-info
                                   showTooltip p-button-outlined" icon="pi pi-print"
                                    [disabled]="rowData.canceled || rowData.inThePast"></button>
                            <button *ngIf="rowData.canceled || rowData.inThePast" pButton pRipple type="button" id="disabledStornoButton"
                                   (click)="stornoConfirm(rowData.shipmentId, rowData.carrier, rowData.state)"
                                    class="p-button-danger p-button-outlined" icon="pi pi-times" [disabled]="rowData.canceled || rowData.inThePast"></button>

                            </div>




                        </td>
                    </tr>
                </ng-template>
            </p-table>

        </div>
    </div>
    </body>
</html>

