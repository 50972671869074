import {Injectable} from "@angular/core";

@Injectable()
export class OrderService {
    public createAndDownloadBlobFile(body, filename, trackingNumber: string) {
        const blob = new Blob([this.base64ToArrayBuffer(body)]);
        let name = '';
        if(filename != null){
            let parsedFilename: string[] = filename.split(".");
            name = parsedFilename[0]+'.pdf';
        }else{
            name = trackingNumber+'.pdf';
        }

        if (navigator.msSaveBlob) {
            console.log("navigator.msSaveBlob - true")
            // IE 10+

            navigator.msSaveBlob(blob, name);
        } else {
            const link = document.createElement('a');
            // Browsers that support HTML5 download attribute
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', name);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }


    base64ToArrayBuffer(base64) {
        const binaryString = window.atob(atob(base64)); // Comment this if not using base64
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }

}