<form [formGroup]="searchForm" (ngSubmit)="apply()">


    <!-- ****************************************   ABSENDER PANEL   ******************************************* -->

    <p-panel header="Absender" class="p-m-1 full-width">

        <div class="p-grid p-mt-2" formGroupName="shipper">

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <p-autoComplete appendTo="body" optionLabel="firma" id="firma" formControlName="name"
                                    (focusout)="mapShipperToPickupOnFocusout()"
                                    [suggestions]="senderAddresses" autofocus (onSelect)="selection($event, 'shipper')"
                                    (completeMethod)="search($event, 'shipper')">
                        <ng-template let-autocompleteSuggestions pTemplate="item">
                            <p>{{ autocompleteSuggestions.formattedAddress }}</p>
                        </ng-template>
                    </p-autoComplete>
                    <label for="firma">Firma</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="street" type="text" pInputText formControlName="street"
                           (focusout)="mapShipperToPickupOnFocusout()"/>
                    <label for="street">Straße</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="housenumber" type="text" pInputText formControlName="streetNumber"
                           (focusout)="mapShipperToPickupOnFocusout()">
                    <label for="housenumber">Hausnummer</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="country" type="text" maxlength="2" pInputText formControlName="countryCode"
                           (focusout)="mapShipperToPickupOnFocusout()">
                    <label for="country">Land</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="postalcode" type="text" pInputText formControlName="postalCode"
                           (focusout)="mapShipperToPickupOnFocusout()">
                    <label for="postalcode">PLZ</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="city" type="text" pInputText formControlName="city"
                           (focusout)="mapShipperToPickupOnFocusout()">
                    <label for="city">Stadt</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="state" type="text" pInputText formControlName="cityState"
                           (focusout)="mapShipperToPickupOnFocusout()">
                    <label for="state">Region</label>
                </span>
            </div>

        </div>

        <div class="p-col-12 p-p-0 ">
            <p-checkbox formControlName="showPickUp" binary="true" label="Querversand"
                        class="extended_margin"></p-checkbox>
        </div>
    </p-panel>

    <!-- ****************************************   ABHOLUNG-VON PANEL - FALLS QUERVERSAND AUSGEWÄHLT   ******************************************* -->

    <p-panel class="extended_margin" header="Abholung von" class="p-m-1 full-width" *ngIf="showPickup">

        <div class="p-grid p-mt-2" formGroupName="shipFrom">

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <p-autoComplete appendTo="body" id="puFirma" formControlName="name"
                                    [suggestions]="pickupAddresses"
                                    (onSelect)="selection($event, 'shipFrom')"
                                    (completeMethod)="search($event, 'shipFrom')">
                        <ng-template let-autocompleteSuggestions pTemplate="item">
                            <p>{{ autocompleteSuggestions.formattedAddress }}</p>
                        </ng-template>
                    </p-autoComplete>
                    <label for="puFirma">Firma</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="puStreet" type="text" pInputText formControlName="street">
                    <label for="puStreet">Straße</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="puHousenumber" type="text" pInputText formControlName="streetNumber">
                    <label for="puHousenumber">Hausnummer</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="puCountry" type="text" maxlength="2" pInputText formControlName="countryCode">
                    <label for="puCountry">Land</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="puPostalcode" type="text" pInputText formControlName="postalCode">
                    <label for="puPostalcode">PLZ</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="puCity" type="text" pInputText formControlName="city">
                    <label for="puCity">Stadt</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="puState" type="text" pInputText formControlName="cityState">
                    <label for="puState">Region</label>
                </span>
            </div>

        </div>
    </p-panel>

    <!-- ****************************************   ZUSTELLUNG-AN PANEL  ******************************************* -->

    <p-panel class="extended_margin" header="Zustellung an" class="p-m-1 full-width">
        <div class="p-grid p-mt-2" formGroupName="shipTo">
            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <p-autoComplete appendTo="body" id="destFirma" formControlName="name"
                                    [suggestions]="destinationAddresses"
                                    (onSelect)="selection($event,'shipTo')"
                                    (completeMethod)="search($event, 'shipTo')">
                        <ng-template let-autocompleteSuggestions pTemplate="item">
                            <p>{{ autocompleteSuggestions.formattedAddress }}</p>
                        </ng-template>
                    </p-autoComplete>
                    <label for="destFirma">Firma</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="destStreet" type="text" pInputText formControlName="street">
                    <label for="destStreet">Straße</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="destHousenumber" type="text" pInputText formControlName="streetNumber">
                    <label for="destHousenumber">Hausnummer</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="destCountry" type="text" maxlength="2" pInputText formControlName="countryCode">
                    <label for="destCountry">Land</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="destPostalcode" type="text" pInputText formControlName="postalCode">
                    <label for="destPostalcode">PLZ</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="destCity" type="text" pInputText formControlName="city">
                    <label for="destCity">Stadt</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="destState" type="text" pInputText formControlName="cityState">
                    <label for="destState">Region</label>
                </span>
            </div>
        </div>
    </p-panel>


    <!-- ****************************************   ZEITEN PANEL   ******************************************* -->

    <p-panel header="Zustell-/Abholzeiten" class="p-m-1 full-width">
        <div class="p-grid p-mt-2 p-jc-start p-col-7">

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <p-calendar appendTo="body" dateFormat="dd'.'mm'.'yy'" showTime="true" id="pickupDate"
                                formControlName="pickUpDate"></p-calendar>
                    <label for="pickupDate">Früheste Abholung</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <p-calendar appendTo="body" dateFormat="dd'.'mm'.'yy'" showTime="true" id="latestPickupDate"
                                formControlName="latestPickupDate"></p-calendar>
                    <label for="latestPickupDate">Späteste Abholung</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <p-calendar appendTo="body" dateFormat="dd'.'mm'.'yy'" showTime="true"
                                formControlName="deliveryDate"
                                id="deliveryDate"></p-calendar>
                    <label for="deliveryDate">Zustellzeit</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <p-calendar appendTo="body" dateFormat="dd'.'mm'.'yy'" showTime="true" id="latestDeliveryDate"
                                formControlName="latestDeliveryDate"></p-calendar>
                    <label for="latestDeliveryDate">Spät. Zustellzeit</label>
                </span>
            </div>
        </div>
    </p-panel>

    <!-- ****************************************   PAKET PANEL   ******************************************* -->

    <p-panel class="extended_margin full-width p-m-1" header="Pakete">
        <div class="p-mt-2">

            <div formArrayName="packageDetails" *ngFor="let item of controls; let i = index; let lst = last">
                <div [formGroupName]="i">

                    <div class="p-grid">
                        <div formGroupName="dimension" class="p-col extended_margin">
                            <span class="p-float-label extended_margin">
                                <input id="packetLength" pInputText formControlName="length" type="number">
                                <label for="packetLength">Länge</label>
                            </span>
                        </div>

                        <div formGroupName="dimension" class="p-col">
                            <span class="p-float-label extended_margin">
                                <input id="packetWidth" pInputText formControlName="width" type="number">
                                <label for="packetWidth">Breite</label>
                            </span>
                        </div>

                        <div formGroupName="dimension" class="p-col">
                            <span class="p-float-label extended_margin">
                                <input id="packetHeight" pInputText formControlName="height" type="number">
                                <label for="packetHeight">Höhe</label>
                            </span>
                        </div>

                        <div class="p-col">
                             <span class="p-float-label extended_margin">
                                 <input id="weight" pInputText formControlName="weight" type="number">
                                 <label for="weight">Gewicht</label>
                             </span>
                        </div>

                        <!--
                        <div class="p-col">
                            <p-multiSelect formControlName="additionalTypes" [options]="searchForm.controls['packageDetails'].value[i].groupedTypes" [group]="true" defaultLabel="Verpackungsart" scrollHeight="250px" display="chip"
                                           (click)="onSelect(searchForm.controls['packageDetails'], i)">
                                <ng-template let-group pTemplate="group">
                                    <div class="p-d-flex p-ai-center" pTooltip="Pro Kurier nur 1 Auswahl möglich">
                                        <img [src]="findCarrierPicture(group.value)"
                                             [class]="'p-mr-2 flag flag-' + group.value" style="width: 50px"/>
                                        <span style="font-weight: bold">{{group.label}}</span>
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </div>
                        -->
                        <div class="p-col">
                            <p-dropdown appendTo="body" formControlName="type" [options]="groupedCarrierTypes"
                                        placeholder="Verpackungsart" scrollHeight="250px" (onClick)="blub()">
                            </p-dropdown>
                        </div>

                        <div class="p-col">
                            <button pButton *ngIf="lst" (click)="addPackage()" icon="pi pi-plus" pRipple
                                    class="p-button-rounded p-mr-1"></button>
                            <button pButton class="p-button-rounded p-button-danger" pRipple (click)="removePackage(i)"
                                    icon="pi pi-minus"></button>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </p-panel>

    <!-- ****************************************   WEITERE SERVICEOPTION PANEL   ******************************************* -->

    <p-panel *ngIf="serviceOptions" header="Weitere Service-Optionen" class="extended_margin full-width"
             [toggleable]="true" [collapsed]="true">
        <div class="p-grid p-mt-2">
            <div *ngFor="let serviceOption of serviceOptions" class="p-col-3">
                <p-checkbox name="group1" #key [inputId]="serviceOption.key" [value]="serviceOption"
                            [label]="serviceOption.humanReadable"
                            [formControl]="searchForm.controls['additionalServices']" class="p-col-6"></p-checkbox>
                <input tabindex="0" *ngIf="serviceOption.valueRequired && key.checked " autofocus pInputText
                       class="p-col-6" [placeholder]="serviceOption.value" pTooltip="{{serviceOption.description}}">
                <div *ngFor="let connector of serviceOption.connectorOptions"
                     class="p-col-1">{{ connector.connector }}
                </div>
            </div>
        </div>
    </p-panel>

    <!-- ****************************************   BUTTONS   ******************************************* -->
    <p-footer>
        <table style="margin-left: auto;">
            <tr>
                <td>
                    <button pButton label="Löschen" type="button" class="p-button-danger p-mr-2" iconPos="right"
                            icon="pi pi-trash" (click)="openDialog()"></button>
                    <button pButton label="Anfragen" iconPos="right" [disabled]="!searchForm.valid"
                            icon="pi pi-envelope"></button>
                    <button pButton label="Mock" iconPos="right" type="button" (click)="mock()"
                    icon="pi pi-envelope"></button>
                </td>
                <td>
                </td>
            </tr>
        </table>
    </p-footer>

    <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
</form>
