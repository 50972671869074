import {Routes} from "@angular/router";
import {ServiceProviderEditComponent} from "../service-provider-edit/service-provider-edit.component";
import {ServiceProviderStartComponent} from "../service-provider-start/service-provider-start.component";
import {ServiceProviderSelectionComponent} from "../service-provider-selection/service-provider-selection.component";


export const SERVICEPROVIDER_ROUTES: Routes =[
    {path: '', component: ServiceProviderStartComponent},
    {path: 'new', component: ServiceProviderEditComponent},
    {path: 'select', component: ServiceProviderSelectionComponent},
    {path: ':name/edit', component: ServiceProviderEditComponent}
]
