<div class="login-center">

    <p-card title="Login">
    <form [formGroup]="loginForm"></form>
        <br/>
    <span class="ui-float-label">
        <input id="float-input" type="text" pInputText formControlName="username"/>
        <label for="float-input">Benutzername</label>
      </span>
    <br/>
    <span class="ui-float-label">
        <input id="float-password" type="password" pInputText formControleName="password" (keyup.enter)="click()"/>
        <label for="float-password">Passwort</label>
      </span>
    <br/>
    <button pButton type="button" label="Login" (click)="click()" (keyup.enter)="click()"></button>
  </p-card>

</div>
