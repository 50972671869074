import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

export const GET = 1;
export const CHANGED = 2;
export const CREATED = 2;

@Injectable()
export class ShipStore {
    searchData: any;
    item: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor() {
    }

    dispatch(action) {
        // console.log("action", action);
        this.searchData = this.reduce(action);
        this.item.next(this.searchData);
    }

    reduce(action) {
        switch (action.type) {
            case GET:
                return this.searchData;
            case CHANGED:
                return this.searchData = action.data;
            default:
                return this.searchData = action.data;
        }
    }
}
