import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment as env} from "../../environments/environment";
import {PersistenceService} from "./persistence.service";
import {OauthModel} from "./login/model/oauth";
import {KeycloakService} from "keycloak-angular";

/**
 * todo: check returned state value!!!!
 */
@Injectable({
    providedIn: 'root'
})
export class OauthService implements OnInit {
    private conf: {} = env.oauth;
    private auth: OauthModel;
    private loginUri: string = '';

    constructor(private http: HttpClient, private persistence: PersistenceService, private keyCloak: KeycloakService) {
    }


    /**
     *
     */
    public hasAuthenticationToken(): boolean {
        return this.persistence.hasAuth() && !this.accessHasExpired();
    }

    /**
     * deletes auth cookie and notifies authorization server to devalidate the token.
     *
     */
    logout() {
        console.log("Keycloak: ", this.keyCloak);
        this.keyCloak.logout().then(() => this.keyCloak.getKeycloakInstance().clearToken());
    }

    ngOnInit(): void {

        this.keyCloak.getKeycloakInstance().onTokenExpired = ()=>{

            this.keyCloak.getKeycloakInstance().updateToken(30).then(function (refeshed) {
            }).catch( ()=> {
                this.keyCloak.getKeycloakInstance().clearToken();
                this.keyCloak.logout();
            });
        }
    }

    /**
     * verifies if token is still alive
     */
    accessHasExpired(): boolean {
        return this.persistence.tokenExpired();
    }

    private static generateRandom(len: number): string {
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (let i = 0; i < len; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }


    public getToken(): string {
        return this.persistence.getToken();
    }

}
