import { HttpInterceptorFn } from '@angular/common/http';
import * as uuid from "uuid";

/**
 * adds allow-origins header
 */

export const tracingInterceptor: HttpInterceptorFn = (req, next) => {
    let t = uuid.v4();
    // Clone the request and add the authorization header
    const authReq = req.clone({
        setHeaders: {
            tracingId: t
        }
    });

    // Pass the cloned request with the updated header to the next handler
    return next(authReq);
};
