import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {ResultList} from "./result.model";

export const GET = 1;
export const CREATE = 2;
export const CLEAR = 3;
export const MODIFY = 4;
export const SINGLE_GET = 5;

@Injectable()
export class Store {
    data: ResultList;
    item: BehaviorSubject<ResultList> = new BehaviorSubject<any>({});

    constructor() {
    }

    dispatch(newData) {
        this.data = newData;
        console.log("new data in store ", this.data)
        this.item.next(this.data);
    }

    reduce(datas, action) {
        switch (action.type) {
            case GET:
                console.log(action.data);
                return action.data;
            case CREATE:
                datas = [...datas, action.data];
                return datas;
            case MODIFY:
                return datas.map(data => {
                    const editedData = action.data;
                    if (data.id != editedData.id) {
                        return data;
                    } else {
                        return editedData;
                    }
                });
            default: CLEAR:
                return new ResultList();
        }
    }
}
