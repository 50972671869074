import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

export const GET = 1;
export const SEARCH = 2;

@Injectable()
export class SearchStore {
    searchData: any;
    item: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor() {
    }

    dispatch(action) {
        this.searchData = this.reduce(action);
        this.item.next(this.searchData);
    }

    reduce(action) {
        switch (action.type) {

            case GET:
                return this.searchData;
            case SEARCH:
                return this.searchData = [action.data][0];
            default:
                return this.searchData = action.data;
        }
    }
}
