import {Component, OnInit} from '@angular/core';
import {DialogService} from "primeng/dynamicdialog";

@Component({
    selector: 'logle-service-provider',
    templateUrl: './service-provider.component.html',
    styleUrls: ['./service-provider.component.scss'],
    providers: [DialogService]
})
export class ServiceProviderComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
