import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Address, AutocompleteSuggestions} from '../model/address.model';


@Injectable()
export class AddressService {

    autocompleteUri = environment.placesService.autocompleteUrl;
    addressUrl = environment.placesService.placeUrl;

    constructor(private httpClient: HttpClient) {

    }

    autocomplete(name: any): Observable<AutocompleteSuggestions[]> {
        console.log(name);
        const params = {q:name.query};
        console.log(params);
        return this.httpClient.get<AutocompleteSuggestions[]>(this.autocompleteUri, {params});
    }

    getPlace(placeId: string): Observable<Address> {
        console.log(`${this.addressUrl}/${placeId}`);
        return this.httpClient.get<Address>(`${this.addressUrl}/${placeId}`);
    }
}
