import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {TenantConnectorPrefs} from "../../model/TenantConnectorPrefs/tenant-connector-prefs";
import {ServiceProviderService} from "../service/provider/service-provider.service";
import {MessageService} from "primeng/api";
import {finalize} from "rxjs/operators";


@Component({
    selector: 'logle-service-provider-edit',
    templateUrl: './service-provider-edit.component.html',
    styleUrls: ['./service-provider-edit.component.scss']
})
export class ServiceProviderEditComponent implements OnInit {

    newConnector: boolean;
    editConnector: boolean;
    connector: TenantConnectorPrefs;
    editable: boolean = false;
    delete: boolean = false;

    constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private serviceProviderService: ServiceProviderService, private messageService: MessageService) {

    }

    ngOnInit(): void {
        this.sortPreferencesFields(this.config.data.connector.tenantConnectorPref);
        this.connector = this.config.data.connector.tenantConnectorPref;
        this.editable = this.config.data.connector.newConnector;
        this.newConnector = this.config.data.connector.newConnector;
        this.editConnector = this.config.data.connector.editConnector;
    }


    sortPreferencesFields(connector: TenantConnectorPrefs){
        console.log("SORTING");
        // @ts-ignore
        let map = [connector.preferences].sort((a: {}, b:{})=>{
            console.log(a);
            console.log(b);
        });

    }


    updatePreferenceValues(pref: any) {
        this.connector.preferences[pref.key] = pref.value;
        console.log("updatePreferenceValues connector after update: ", this.connector);
    }

    findCarrierPictureForOverview(carrier: string): string {

        var imagePath: string;
        var carrierToCompare = carrier.toUpperCase();

        switch (carrierToCompare) {

            case 'FEDEX':
                imagePath = "assets/images/logos/FEDEX_M.png";
                break;

            case 'TNT':
                imagePath = "assets/images/logos/TNT_M.png";
                break;

            case 'DHLEXPRESS':
                imagePath = "assets/images/logos/DHLEXPRESS_M.png";
                break;

            case 'UPS':
                imagePath = "assets/images/logos/UPS_M.png";
                break;

            default:
                imagePath = "assets/images/logos/EXTERN_M.png";
        }
        return imagePath;
    }


    uploadConnector() {
        console.log("This Connector to update: ", this.connector);
        this.serviceProviderService.addConnector(this.connector).pipe(finalize(() => {
            this.closeDialog('Upload');
        })).subscribe({
                next: value => {
                    console.log("Upload Success: ", value);
                    this.showSuccess();
                },
                error: err => {
                    console.log("Upload Error: ", err);
                    this.showError();
                }
            }
        );
    }

    deactivateConnector(){
        this.serviceProviderService.deactivateConnector(this.connector.carrier).pipe(finalize(() => {
            this.closeDialog('Deactivated');
        })).subscribe({
                next: value => {
                    console.log("Upload Success: ", value);
                    this.showSuccess();
                },
                error: err => {
                    console.log("Upload Error: ", err);
                    this.showError();
                }
            }
        );
    }

    activateConnector(){
        this.serviceProviderService.activateConnector(this.connector.carrier).pipe(finalize(() => {
            this.closeDialog('Activated');
        })).subscribe({
                next: value => {
                    console.log("Upload Success: ", value);
                    this.showSuccess();
                },
                error: err => {
                    console.log("Upload Error: ", err);
                    this.showError();
                }
            }
        );
    }

    deleteConnector() {
        this.serviceProviderService.deleteConnector(this.connector.carrier).pipe(finalize(() => {
            this.closeDialog('Löschen');
        })).subscribe({
            next: value => {
                console.log("Delete Success: ", value);
                this.showSuccess();
            },
            error: err => {
                console.log("Delete Error: ", err);
                this.showError();
            }
        });
    }


    showSuccess() {
        this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Provider erfolgreich aktualisiert!'
        });
    }

    showError() {
        this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Bearbeiten des Providers aktuell nicht möglich!'
        });
    }


    closeDialog(data: any) {
        this.ref.close(data);
    }

    showDeleteDialog(){
        this.delete = !this.delete;
    }

    evaluateEditPrivileges(){
        this.editable = !this.editable;
    }

}
