import {Component} from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="footer clearfix">
        <span>Copyright Cloud-Surfers GmbH</span>
        <div class="footer-links">
            <a href="#" class="first">Bedingungen</a>
            <span class="link-divider">|</span>
            <a href="#">Über uns</a>
            <span class="link-divider">|</span>
            <a href="#">Datenschutz</a>
            <span class="link-divider">|</span>
            <a href="#">Contatct</a>
            <span class="link-divider">|</span>
            <a href="#">Karte</a>
        </div>
    </div>
    `
})
export class AppFooterComponent {

}
