import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {
    AbstractControl,
    FormGroup,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import {ConfirmationService, Message} from 'primeng/api';
import {ShipmentService} from '../search/shipment.service';
import {ServiceOption} from '../model/constants';
import {Subscription} from 'rxjs';
import {RatesService} from '../search/search.service';
import * as moment from 'moment';
import {ShipStore} from './ship.store';
import {Router} from '@angular/router';
import {MessageStore} from '../message/message.store';
import {Notification} from '../model/notification';
import {ShippingFormProvider} from './shipping.form.provider';

@Component({
    selector: 'logle-ship',
    templateUrl: './ship.component.html',
    styleUrls: ['../search/search.component.scss'],
    styles: [`
        .redBorder {
            border-color: red !important;
        }
    `],
    encapsulation: ViewEncapsulation.None
})
export class ShipComponent implements OnInit {

    constructor(private formBuilder: UntypedFormBuilder,
                private shippingService: ShipmentService,
                private confirmationService: ConfirmationService,
                private router: Router,
                private messageStore: MessageStore,
                private ratesService: RatesService,
                private shipStore: ShipStore) {
    }

    @Output()
    canceled = new EventEmitter<boolean>();
    serviceOptionsSubscription: Subscription;

    @Output()
    sumbitOrder = new EventEmitter<boolean>();

    shipping: any = {};

    disabled = true;

    controls: AbstractControl[];
    serviceOptions: ServiceOption[];
    messages: Message[] = [];

    packages: UntypedFormArray;
    private shippingFormProvider: ShippingFormProvider = new ShippingFormProvider(this.formBuilder);
    shippingForm = this.shippingFormProvider.blub();

    msgs: Message[] = [];
    loading = false;

    ngOnChanges() {
        this.messages = [];

        this.shippingForm.patchValue(this.shipping);

        if (this.shipping.shipper) {
            this.shippingForm.controls.shipper.patchValue(this.shipping.shipper);
        }

        if (this.shipping.shipFrom) {
            this.shippingForm.controls.shipFrom.patchValue(this.shipping.shipFrom);
        }

        if (this.shipping.recipient) {
            this.shippingForm.controls.shipTo.patchValue(this.shipping.shipTo);
        }

        this.packages = this.shippingForm.controls.packages;
        this.packages.clear();

        console.log(JSON.stringify(this.shipping));
        for (let i = 0; i < this.shipping.packages.length; i++) {
            this.packages.push(this.createPackages(this.shipping.packages[i]));
        }

        /*
        console.log("shipper companyName: ", this.shippingForm.get('shipper').get('companyName').valid);
        console.log("shipper street: ", this.shippingForm.get('shipper').get('street').valid);
        console.log("shipper houseNr: ", this.shippingForm.get('shipper').get('houseNr').valid);
        console.log("shipper zipCode: ", this.shippingForm.get('shipper').get('zipCode').valid);
        console.log("shipper city: ", this.shippingForm.get('shipper').get('city').valid);
        console.log("shipper countryCode: ", this.shippingForm.get('shipper').get('countryCode').valid);

        console.log("recipient companyName: ", this.shippingForm.get('recipient').get('companyName').valid);
        console.log("recipient street: ", this.shippingForm.get('recipient').get('street').valid);
        console.log("recipient houseNr: ", this.shippingForm.get('recipient').get('houseNr').valid);
        console.log("recipient zipCode: ", this.shippingForm.get('recipient').get('zipCode').valid);
        console.log("recipient city: ", this.shippingForm.get('recipient').get('city').valid);
        console.log("recipient countryCode: ", this.shippingForm.get('recipient').get('countryCode').valid);

         */
    }

    ngOnInit() {
        console.log('ShipComponent - ngOnInit()')
        const packages = this.shippingForm.get('packages') as UntypedFormArray;
        this.controls = packages.controls;
        // console.log(this.shipping);

        // When does this gets triggered, how does it get instantiated?
        this.serviceOptionsSubscription = this.ratesService.shipmentOptionsSubject.subscribe(options => {
            console.log('ShipComponent-OPTIONSBRA: ', options);
            this.serviceOptions = options
        });
        // When does this gets triggered, how does it get instantiated?
        this.shipStore.item.subscribe(value => {
            console.log('ShipComponent-ShipStore.item -> Value: ', value);
            this.shipping = value;
            console.log(this.shipping);
            this.ngOnChanges();
        });
    }

    createPackages(packet: any): UntypedFormGroup {
        return this.formBuilder.group({
            type: [packet.type, Validators.required],
            // tslint:disable-next-line:max-line-length
            // The outcommented variables are disabling this compoenent from reseting the values if we get a second time to ship componenent..
            // code:[packet.code],
            // additionalTypes: [packet.additionalTypes],
            // groupedTypes: this.formBuilder.array([packet.groupedTypes]),
            weight: [packet.weight, Validators.required],
            dimension: this.formBuilder.group({
                height: [packet.dimension.height, Validators.required],
                length: [packet.dimension.length, Validators.required],
                width: [packet.dimension.width, Validators.required],
            })
        });
    }

    addressToFormGroup(address: any): FormGroup {
        // console.log(address);
        const formGroup = this.formBuilder.group({
            city: [address.city, Validators.required],
            cityState: [address.cityState],
            companyName: [address.companyName, Validators.required],
            countryCode: [address.countryCode, Validators.required],
            emailAddress: [address.emailAddress, Validators.required],
            houseNr: [address.houseNr, Validators.required],
            personName: [address.personName, Validators.required],
            phoneNumber: [address.phoneNumber, Validators.required],
            street: [address.street, Validators.required],
            postalCode: [address.postalCode, Validators.required]
        });

        return formGroup;
    }

    confirm() {
        console.log('SHIPPINGFORM.VALUE:', this.shippingForm.value);
        const shipObject = this.shippingForm.value;
        const shipmentOptions = this.shippingForm.get('additionalServices').value as ServiceOption[];
        const blub = {};

        if (shipmentOptions) {
            shipmentOptions.forEach(shipmentOption => {
                blub[shipmentOption.name] = shipmentOption.value;
            })
        }
        shipObject.additionalServices = blub;

        this.confirmationService.confirm({

            message: `Die Kosten für den Auftrag betragen ${this.shipping.rateCharge} ${this.shipping.currency}.
                    Möchten Sie ${this.shipping.carrier} mit dem Versand beauftragen?`,
            header: 'Auftragsbestätigung',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                const shipmentOptions = this.shippingForm.get('additionalServices').value as ServiceOption[];
                const blub = {};
                this.loading = true;
                if (shipmentOptions) {
                    shipmentOptions.forEach(shipmentOption => {
                        blub[shipmentOption.name] = shipmentOption.value;
                    });
                }
                const ship = this.shippingForm.value;
                ship.additionalServices = blub;

                ship.deliveryDate = this.shipping.deliveryDate;
                ship.latestDeliveryDate = this.shipping.latestDeliveryDate;
                ship.pickUpDate = this.shipping.pickUpDate;
                ship.latestPickupDate = this.shipping.latestPickupDate;
                this.shippingService.ship(ship).subscribe(value => {
                    this.sumbitOrder.emit(true);
                    this.loading = false;
                }, error => {
                    const map = new Map(Object.entries(error.error.notifications));
                    const notify: Notification[] = [];
                    const counter = 0;
                    for (const [key, value] of map) {
                        const note = new Notification();
                        note.code = key;
                        note.description = value;
                        notify.push(note);
                    }

                    this.loading = false;
                    let errorOutput: any = '';
                    for (const val of notify) {
                        errorOutput += val.description + '(' + val.code + ')' + '; ';
                    }
                    const message = {
                        life: 5,
                        severity: 'error',
                        detail: errorOutput,
                        summary: '',
                        icon: 'p-message-icon'
                    } as Message;
                    this.messages = [message];
                });

            },
            reject: () => {
            }
        });
    }

    reset() {
        this.confirmationService.confirm({
            message: 'Möchten Sie eine neue Suche starten? Die Eingaben gehen verloren.',
            header: 'Neue Suche',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.shipping = {};
                this.shippingForm.reset();
                this.canceled.emit(true);
            },
            reject: () => {
            }
        });
    }
}
