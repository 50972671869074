import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SearchComponent} from './search/search.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {AutoCompleteModule} from "primeng/autocomplete";
import {InputMaskModule} from "primeng/inputmask";
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {RatesService} from "./search/search.service";
import {Store} from "./results/generic.store";

import {LogoutComponent} from "./security/logout/logout.component";
import {LoginComponent} from "./security/login/login.component";
import {CardModule} from "primeng/card";
import {AuthGuardService} from "./interceptors/auth-guard.service";
import {ResultsComponent} from './results/results.component';
import {TableModule} from "primeng/table";
import {AddressService} from "./services/address.service";
import {ShipmentService} from "./search/shipment.service";
import {SearchStore} from "./search/search.store";
import {OrderOverviewComponent} from './order-overview/order-overview.component';
import {SplitButtonModule} from 'primeng/splitbutton';
import {SelectButtonModule} from 'primeng/selectbutton';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ToastModule} from 'primeng/toast';
import {ShipComponent} from "./ship/ship.component";
import {SearchDetailComponent} from "./searchDetail/search.detail.component";
import {CheckboxModule} from "primeng/checkbox";
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {DialogModule} from 'primeng/dialog';
import {ServiceOptionStore} from "./search/service.option.store";
import {ShipStore} from "./ship/ship.store";
import {FieldsetModule} from "primeng/fieldset";
import {AccordionModule} from "primeng/accordion";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {TooltipModule} from "primeng/tooltip";
import {ProgressBarModule} from "primeng/progressbar";
import {ConfirmationService, MessageService} from "primeng/api";
import {PanelModule} from "primeng/panel";
import {KeycloakAngularModule, KeycloakService} from "keycloak-angular";
import {environment} from "../environments/environment";
import {AppMainComponent} from "./app.main.component";
import {AppTopBarComponent} from "./app.topbar.component";
import {AppSideBarComponent} from "./app.sidebar.component";
import {AppMenuComponent} from "./app.menu.component";
import {AppFooterComponent} from "./app.footer.component";
import {AppConfigComponent} from "./app.config.component";
import {AppSideBarTabContentComponent} from "./app.sidebartabcontent.component";
import {AppMenuitemComponent} from "./app.menuitem.component";
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {RippleModule} from "primeng/ripple";
import {MenuService} from "./app.menu.service";
import {MessageModule} from "primeng/message";
import {MessagesModule} from "primeng/messages";
import {MessageStore} from "./message/message.store";
import {OrderStore} from "./order-overview/order.store";
import {OrderService} from "./order-overview/order.service";
import {DropdownModule} from "primeng/dropdown";
import {TagModule} from 'primeng/tag';
import {MultiSelectModule} from "primeng/multiselect";
import {InplaceModule} from 'primeng/inplace';
import {ServiceProviderComponent} from './service-provider/service-provider.component';
import {ToolbarModule} from "primeng/toolbar";
import {ServiceProviderEditComponent} from './service-provider/service-provider-edit/service-provider-edit.component';
import {InputNumberModule} from "primeng/inputnumber";
import {RadioButtonModule} from "primeng/radiobutton";
import {
    ServiceProviderStartComponent
} from './service-provider/service-provider-start/service-provider-start.component';
import {FileUploadModule} from "primeng/fileupload";
import {
    ServiceProviderSelectionComponent
} from './service-provider/service-provider-selection/service-provider-selection.component';
import {DataViewModule} from 'primeng/dataview';
import {RatingModule} from "primeng/rating";
import {FocusTrapModule} from 'primeng/focustrap';
import {CalendarModule} from "primeng/calendar";
import {ResultListsStore} from "./results/result.list.store";
import {tracingInterceptor} from "./interceptors/tracing-interceptor";
import {FloatLabelModule} from "primeng/floatlabel";

declare global{
    interface Navigator{
        msSaveBlob:(blob: Blob,fileName:string) => boolean
    }
}

function initializeKeycloak(keycloak: KeycloakService) {
    return () =>
        keycloak.init({
            config: environment.keycloak,
            initOptions: {
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri:
                    window.location.origin + '/assets/silent-check-sso.html'
            }
        });
}

@NgModule({
    declarations: [
        AppComponent,
        AppMainComponent,
        AppTopBarComponent,
        AppSideBarComponent,
        AppMenuComponent,
        AppFooterComponent,
        AppConfigComponent,
        AppMenuitemComponent,
        AppSideBarTabContentComponent,
        SearchComponent,
        SearchDetailComponent,
        LoginComponent,
        LogoutComponent,
        ResultsComponent,
        OrderOverviewComponent,
        ShipComponent,
        ServiceProviderComponent,
        ServiceProviderEditComponent,
        ServiceProviderStartComponent,
        ServiceProviderSelectionComponent,
    ],
    imports: [
        MultiSelectModule,
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        /*HttpClientModule,*/
        BrowserAnimationsModule,
        ReactiveFormsModule,
        InputTextModule,
        ButtonModule,
        FieldsetModule,
        AutoCompleteModule,
        InputMaskModule,
        CardModule,
        AccordionModule,
        ScrollPanelModule,
        TableModule,
        SplitButtonModule,
        SelectButtonModule,
        FormsModule,
        ConfirmDialogModule,
        ToastModule,
        CheckboxModule,
        DynamicDialogModule,
        DialogModule,
        TooltipModule,
        PanelModule,
        ProgressBarModule,
        ProgressSpinnerModule,
        RippleModule,
        MessageModule,
        MessagesModule,
        DropdownModule,
        TagModule,
        InplaceModule,
        ToolbarModule,
        InputNumberModule,
        RadioButtonModule,
        FileUploadModule,
        DataViewModule,
        RatingModule,
        FocusTrapModule,
        KeycloakAngularModule,
        CalendarModule,
        FloatLabelModule
    ],
    providers: [
        provideHttpClient(withInterceptors([tracingInterceptor])),
        { provide: "searchRates", useValue: environment.shippingService.searchUri },
        ResultListsStore,
        RatesService,
        Store, ShipStore,
        AuthGuardService,
        AddressService,
        ShipmentService,
        MenuService,
        SearchStore,
        MessageService,
        MessageStore,
        OrderStore,
        OrderService,
        ServiceOptionStore,
        KeycloakService,
        AppMainComponent,
        ConfirmationService, {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService]
        }],
    bootstrap: [AppComponent]
})
export class AppModule {
}
