export class ResultList {
    carrierResults: Result[] = [];
    errors: BackendErrors[] = [];
    message: string;
}

export class BackendErrors{
    carrier: string;
    error: Object;
}

export class Result{
    carrierName: string;
    carrier:string;
    netCharge:number;
    serviceType:string;
    serviceDescription?:string;
    billingWeight?:number;
    pickupDate:Date;
    deliveryDate?:Date;
}
