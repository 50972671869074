import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';

import {AuthGuardService} from "./interceptors/auth-guard.service";
import {SearchComponent} from "./search/search.component";
import {OrderOverviewComponent} from "./order-overview/order-overview.component";
import {LogoutComponent} from "./security/logout/logout.component";
import {AppMainComponent} from "./app.main.component";
import {ServiceProviderComponent} from "./service-provider/service-provider.component";
import {SERVICEPROVIDER_ROUTES} from "./service-provider/routing/serviceprovider-routing";

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                children: [
                    {path: '', component: SearchComponent, canActivate: [AuthGuardService]},
                    {path: "orderview", component: OrderOverviewComponent, canActivate: [AuthGuardService]},
                    {path: "serviceprovider", component: ServiceProviderComponent,canActivate: [AuthGuardService], children: SERVICEPROVIDER_ROUTES},
                    {path: "logout", component: LogoutComponent, canActivate: [AuthGuardService]},
                ]
            },
        ], { scrollPositionRestoration: 'enabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
