<div style="display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 20%;">
    <p-card *ngIf="shipping!=null">
        <ng-template pTemplate="header">
            <img src="assets/images/logos/{{shipping['carrier']}}_M.png">
        </ng-template>
        <br>{{shipping['serviceDescription']}}, {{shipping['rateCharge']}} {{shipping['currency']}}
    </p-card>

</div>

<p-messages [value]="messages"></p-messages>

<form [formGroup]="shippingForm" (submit)="confirm()" #shipForm>

    <p-panel legend="Absender" class="extended_margin full-width">

        <div class="form-group p-grid p-mt-2" formGroupName="shipper">
            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input pInputText id="firma" formControlName="name">
                    <label for="firma">Firma</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="street" type="text" pInputText formControlName="street">
                    <label for="street">Straße</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="housenumber" type="text" pInputText formControlName="streetNumber">
                    <label for="housenumber">Hausnummer</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="country" type="text" maxlength="2" pInputText formControlName="countryCode"
                           disabled="disabled">
                    <label for="country">Land</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="postalcode" type="text" pInputText formControlName="postalCode" disabled="disabled">
                    <label for="postalcode">PLZ</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="city" type="text" pInputText formControlName="city" disabled="disabled">
                    <label for="city">Stadt</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="state" type="text" pInputText formControlName="cityState" disabled="disabled">
                    <label for="state">Region</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="personName" [ngClass]="shippingForm.controls.shipper.controls.personName.invalid ? 'redBorder': null" type="text" pInputText formControlName="personName" pattern=".*\S.*">
                    <label for="personName">Kontakt</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="phoneNumber" [ngClass]="shippingForm.controls.shipper.controls.phoneNumber.invalid ? 'redBorder': null" type="text" pInputText  formControlName="phoneNumber">
                    <label for="phoneNumber">Tel.</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="emailAddress" [ngClass]="shippingForm.controls.shipper.controls.emailAddress.invalid ? 'redBorder' : null" type="text" pInputText formControlName="emailAddress" pattern=".*\S.*">
                    <label for="emailAddress">E-Mail</label>
                </span>
            </div>

        </div>
    </p-panel>

    <p-panel class="extended_margin" legend="Abholung von">

        <div class="p-grid p-mt-2" formGroupName="shipFrom">
            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input pInputText id="puFirma" formControlName="name"/>
                    <label for="puFirma">Firma</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="puStreet" type="text" pInputText formControlName="street">
                    <label for="puStreet">Straße</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="puHousenumber" type="text" pInputText formControlName="streetNumber">
                    <label for="puHousenumber">Hausnummer</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="puCountry" type="text" maxlength="2" pInputText formControlName="countryCode" disabled="disabled">
                    <label for="puCountry">Land</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="puPostalcode" type="text" pInputText formControlName="postalCode" disabled="disabled">
                    <label for="puPostalcode">PLZ</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="puCity" type="text" pInputText formControlName="city" disabled="disabled">
                    <label for="puCity">Stadt</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="puState" type="text" pInputText formControlName="cityState" disabled="disabled">
                    <label for="puState">Region</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="puKontakt" type="text" [ngClass]="shippingForm.controls.shipFrom.controls.personName.invalid ? 'redBorder': null" pInputText formControlName="personName"  pattern=".*\S.*">
                    <label for="puKontakt">Kontakt</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="puPhoneNumber" [ngClass]="shippingForm.controls.shipFrom.controls.phoneNumber.invalid ? 'redBorder': null" type="text" pInputText formControlName="phoneNumber">
                    <label for="puPhoneNumber">Tel.</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="puEmailAddress" type="text" [ngClass]="shippingForm.controls.shipFrom.controls.emailAddress.invalid ? 'redBorder': null" pInputText formControlName="emailAddress" pattern=".*\S.*">
                    <label for="puEmailAddress">E-Mail</label>
                </span>
            </div>

        </div>
    </p-panel>

    <p-panel class="extended_margin" legend="Zustellung an">

        <div class="p-grid p-mt-2" formGroupName="shipTo">
            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input pInputText id="destFirma" formControlName="name">
                    <label for="destFirma">Firma</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="destStreet" type="text" pInputText formControlName="street">
                    <label for="destStreet">Straße</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="destHousenumber" type="text" pInputText formControlName="streetNumber">
                    <label for="destHousenumber">Hausnummer</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="destCountry" type="text" maxlength="2" pInputText formControlName="countryCode" disabled="disabled">
                    <label for="destCountry">Land</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="destPostalcode" type="text" pInputText formControlName="postalCode" disabled="disabled">
                    <label for="destPostalcode">PLZ</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="destCity" type="text" pInputText formControlName="city" disabled="disabled">
                    <label for="destCity">Stadt</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="destState" type="text" pInputText formControlName="cityState" disabled="disabled">
                    <label for="destState">Region</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="destKontakt" type="text" [ngClass]="shippingForm.controls.shipTo.controls.personName.invalid ? 'redBorder': null" pInputText formControlName="personName" pattern=".*\S.*">
                    <label for="destKontakt">Kontakt</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="destPhoneNumber" [ngClass]="shippingForm.controls.shipTo.controls.phoneNumber.invalid ? 'redBorder': null" type="text" pInputText formControlName="phoneNumber">
                    <label for="destPhoneNumber">Tel.</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input id="destEmailAddress" type="text" [ngClass]="shippingForm.controls.shipTo.controls.emailAddress.invalid ? 'redBorder': null" pInputText formControlName="emailAddress" pattern=".*\S.*">
                    <label for="destEmailAddress">E-Mail</label>
                </span>
            </div>

        </div>

    </p-panel>

    <p-panel legend="Zustell-/Abholzeiten">

        <div class="p-grid p-mt-2">
            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <p-inputMask mask="99.99.9999 99:99" id="pickupDate" type="datetime" [disabled]="disabled" formControlName="pickUpDate"></p-inputMask>
                    <label for="pickupDate">Früheste Abholung</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <p-inputMask mask="99.99.9999 99:99" id="latestPickupDate" type="datetimee" [disabled]="disabled" formControlName="latestPickupDate"></p-inputMask>
                    <label for="latestPickupDate">Späteste Abholung</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <p-inputMask mask="99.99.9999 99:99" id="deliveryDate" type="datetime" [disabled]="disabled" formControlName="deliveryDate"></p-inputMask>
                    <label for="deliveryDate">Zustellung</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <p-inputMask mask="99.99.9999 99:99" id="latestDeliveryDate" type="datetime" [disabled]="disabled" formControlName="latestDeliveryDate"></p-inputMask>
                    <label for="latestDeliveryDate">Späteste Zustellung</label>
                </span>
            </div>

            <div class="p-col"></div>
            <div class="p-col"></div>
            <div class="p-col"></div>
        </div>
    </p-panel>

    <div class="p-grid ">
        <div class="p-col-12">
            <p-panel class="extended_margin full-width" header="Pakete">

                <div class="p-mt-2">
                    <div formArrayName="packages"
                         *ngFor="let item of controls; let i = index; let lst = last">
                        <div [formGroupName]="i">
                            <div class="p-grid">
                                <div formGroupName="dimension" class="p-col">
                                    <input pInputText formControlName="length" type="number" disabled="disabled"
                                           placeholder="Länge">
                                </div>
                                <div formGroupName="dimension" class="p-col">
                                    <input pInputText formControlName="width" type="number" disabled="disabled"
                                           placeholder="Breite">
                                </div>
                                <div formGroupName="dimension" class="p-col">
                                    <input pInputText formControlName="height" type="number" placeholder="Höhe"
                                           disabled="disabled">
                                </div>
                                <div class="p-col">
                                    <input pInputText formControlName="weight" type="number" placeholder="Gewicht"
                                           disabled="disabled">
                                </div>
                                <div class="p-col">
                                    <input pInputText formControlName="type" type="text" disabled="disabled"
                                           placeholder="Verpackungsart">
                                </div>

                                <div class="p-col">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </p-panel>
        </div>
    </div>

    <p-panel *ngIf="serviceOptions" header="Weitere Service-Optionen" class="extended_margin full-width"
             [toggleable]="true"
             [collapsed]="true">
        <div class="p-grid">
            <div *ngFor="let serviceOption of serviceOptions" class="p-col-3">
                <p-checkbox name="group1" #key [inputId]="serviceOption.key" [value]="serviceOption"
                            [label]="serviceOption.humanReadable" [disabled]="disabled"
                            [formControl]="shippingForm.controls['additionalServices']" class="p-col-6"></p-checkbox>
                <input tabindex="0" *ngIf="serviceOption.valueRequired && key.checked " autofocus pInputText
                       class="p-col-6"
                       [placeholder]="serviceOption.value" pTooltip="{{serviceOption.description}}"
                       [disabled]="disabled">
            </div>
        </div>
    </p-panel>

    <p-footer>
        <table style="margin-left: auto;">
            <tr>
                <td>
                    <button type="button" (click)="reset()" class="p-button-danger p-mr-2" pButton icon="pi pi-search"
                            label="Neue Anfrage"></button>
                    <button *ngIf="shipping['carrier']" pButton label="Bestätigen" type="submit" iconPos="right"

                            icon="pi pi-envelope" class="p-mr-2"></button>
                </td>
                <td>
                </td>
            </tr>
        </table>
    </p-footer>

</form>

<p-confirmDialog [style]="{width: '50vw'}" acceptLabel="Ja" rejectLabel="Nein" acceptIcon="pi pi-success" rejectIcon="pi pi-times" appendTo="body"></p-confirmDialog>


<div>
    <p-progressSpinner class="spinnerLayout" *ngIf="loading"></p-progressSpinner>
</div>
