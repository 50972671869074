import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {OauthService} from "../oauth.service";
import {UntypedFormBuilder} from "@angular/forms";

/**
 * uses LoginService to execute a login for user.
 * 1. if user opened a login directly, then after successful login he's redirected to a default (todo: to configure) page
 * 2. if user tried to open another page, like ratings-form and has to confirm his login, then after successful login he's
 *    redirected to this initial page
 *
 *    todo: i18n
 *    todo: make this configurable
 *    todo: add logging
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  failedLogin: boolean = false;
  msgs: object[];
  redirectAfterLogin: string = "/test-input";

  loginForm = this.formbuilder.group({username: '', password:''});

  constructor(private loginService: OauthService, private router: Router, private formbuilder:UntypedFormBuilder) {
  }

  ngOnInit() {
    console.log("login-component activated")
  }

  click()
{
   /* this.loginService.login(this.userName, this.password).subscribe(b => {
        this.msgs = [];
        if (b) {
          this.failedLogin = false;
          let navigationExtras: NavigationExtras = {
            queryParamsHandling: 'preserve',
            preserveFragment: true
          };
          this.router.navigate([this.redirectAfterLogin], navigationExtras);
        } else {
          this.failedLogin = true;
        }
      },
      e => {
        this.failedLogin = true;
      }, () => {
        if (this.failedLogin === true) {
          this.msgs.push({severity: 'error', summary: '', detail: 'Anmeldung fehlgeschlagen'});
        }
      });*/
  }
}
