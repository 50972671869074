import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DateTime} from 'luxon';

export const defaultHours = {
    hour: 12,
    minute: 0
}

export const LATEST_DATE_OFFSET = 1;
export const DELIVERY_DATE_OFFSET = 1;

export class SearchFormProvider {

    constructor(private formbuilder: FormBuilder) {
    }

    createAllControls() {
        return {
            showPickUp: new FormControl<boolean>(false),
            shipFrom: this.createAddress(),
            shipper: this.createAddress(),
            shipTo: this.createAddress(),
            packageDetails: this.formbuilder.array([this.createPackage()]),
            ...this.createSearchFormDeliveryDates()
        }
    }

    createSearchForm() {
        return this.formbuilder.group(this.createAllControls());
    }


    createAddress() {
        return this.formbuilder.group({
            name: new FormControl<string>('', Validators.required),
            street: new FormControl<string>('', Validators.required),
            streetNumber: new FormControl<string>('', Validators.required),
            postalCode: new FormControl<string>('', Validators.required),
            city: new FormControl<string>('', Validators.required),
            countryCode: new FormControl<string>('', Validators.required),
            cityState: new FormControl<string>(''),
            personName: new FormControl<string>(''),
            phoneNumber: new FormControl<string>(''),
            emailAddress: new FormControl<string>(''),
        })

    }

    createPackage() {
        return this.formbuilder.group({
            weight: new FormControl<number>(0, Validators.required),
            type: new FormControl<string>('', Validators.required),
            code: new FormControl<string>(''),
            additionalTypes: new FormControl<string>(null),
            dimension: this.createDimension()
        })
    }

    private createDimension() {
        return this.formbuilder.group({
            height: new FormControl<number>(0, Validators.required),
            length: new FormControl<number>(0, Validators.required),
            width: new FormControl<number>(0, Validators.required),
        })
    }


    createSearchFormDeliveryDates() {
        return {
            deliveryDate: new FormControl<Date>(this.convertToJSDate(this.createDeliveryDate()), Validators.required),
            latestDeliveryDate: new FormControl<Date>(this.convertToJSDate(this.createDeliveryDate()
                .plus({day: DELIVERY_DATE_OFFSET})), Validators.required),
            pickUpDate: new FormControl<Date>(this.convertToJSDate(this.createPickupDate()), Validators.required),
            latestPickupDate: new FormControl<Date>(this.convertToJSDate(this.createPickupDate()
                .plus({day: LATEST_DATE_OFFSET})), Validators.required),
        }
    }

    createDeliveryDate() {
        return DateTime.now().set({
            day: DELIVERY_DATE_OFFSET,
            ...defaultHours
        }).setZone('Europe/Berlin', {keepLocalTime: true})
    }

    createPickupDate() {
        return DateTime.now().set(defaultHours).setZone('Europe/Berlin', {keepLocalTime: true})
    }

    convertToJSDate(dt
                        :
                        DateTime
    ):
        Date {
        return dt.toJSDate();
    }


    resetForm() {
        return this.createSearchForm();
    }

}
