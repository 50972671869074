<body>
<p-toast [style]="{marginTop: '80px'}"></p-toast>

<div class="p-grid">
    <p-card class="p-col-12">
        <h4>Ihre Dienstleister</h4>
    </p-card>


    <div class="p-col-12"></div>
    <div class="p-col-12"></div>

    <p-card [ngClass]="connectors.active? 'activated':'deactivated'" class="p-col-5"
            *ngFor="let connectors of tenantConnectorPrefs"
            [style]="{ 'text-align': '-webkit-center'}">
        <ng-template pTemplate="header">
            <p style="font-size: 16px;font-weight: bolder;">{{connectors.carrier}}<span *ngIf="!connectors.active"> - (Deaktiviert)</span></p>
        </ng-template>


        <a href="javascript:void(0);" (click)="editDialog(connectors)">
            <img [src]="findCarrierPictureForOverview(connectors.carrier)" [alt]="connectors.carrier" width="150px">
        </a>


    </p-card>

    <p-card class="p-col-5" [style]="{ 'text-align': '-webkit-center'}">
        <ng-template pTemplate="header">
            <b>Add New provider</b>
        </ng-template>
        <p-button class="addButton" icon="pi pi-plus" (onClick)="openNew()">
        </p-button>
    </p-card>

</div>

</body>
