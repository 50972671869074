import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ConfirmationService, LazyLoadEvent, Message, MessageService} from "primeng/api";
import {Order} from "../model/order-model";
import {OrderOverviewService} from "./order-overview.service";
import {finalize} from "rxjs/operators";
import {formatDate} from "@angular/common";
import {Table} from "primeng/table";
import {Documents} from "../model/documents";


// noinspection CssUnusedSymbol
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'logle-order-overview',
  templateUrl: './order-overview.component.html',
  styleUrls: ['./order-overview.component.scss'],
  styles:[
    `.selectedsendung{ background-color: #90A4AE !important; color: black !important;}
        .null{background-color: #ffffff !important; color: black !important;}
        .canceled{background-color: #B71C1C !important; color: white !important;}
        .ordered{background-color: #77C159 !important;; color: white !important;}
    `],
  providers:[ConfirmationService, MessageService]
})
export class OrderOverviewComponent implements OnInit {


  @ViewChild('dt', {static: false}) tableComponent: Table;

  rows: number = 10;
  first: number;
  sortField: string;
  sortOrder: number;

  rowsPerPageOptions: number[] = [5, 10, 20, 40, 80];
  totalCount: number;
  lazyLoading: boolean = false;
  loading: boolean = false;

  sendungen : Order[] = [];
  datasource: Order[] = [];
  cols: any[];
  selectedSendung: Order;
  suche: boolean = false;
  searchtext: string = "";
  format: string = 'dd.MM.yyyy';

  rowGroupMetadata : any;

  stornoMsg: Message[] = [];
  forceStornoMsg: Message[] = [];
  labelMsg: Message[] = [];

  labelDocuments: Documents[]  = [];
  displayDialog: boolean = false;

  constructor(private orderService: OrderOverviewService, private confirmService: ConfirmationService, private messageService: MessageService) { }

  ngOnInit() {
    this.cols = [
      {field: 'state', header: 'Status'},
      {field: 'trackingNumber', header: 'Referenz-ID'},
      {field: 'shipTimestamp', header: 'Auftr. am:'},
      {field: 'shipFromPersonName', header: 'Absender'},
      {field: 'shipFromStreet', header: 'Abs. Str.'},
      {field: 'shipFromCity', header: 'Abs. Ort'},
      {field: 'recipientPersonName', header: 'Empfänger'},
      {field: 'recipientStreet', header: 'Empf. Str.'},
      {field: 'recipientCity', header: 'Empf. Ort'},
      {field: 'carrier', header: 'Kurier'},
      {field: 'pickUpDate', header: 'Zustell. bis:'},
      {field: 'charge', header: 'Preis'},
    ];

    this.searchtext = " Filter einblenden";
  }


  stornoConfirm(trackingNumber, connector, state: string){
    this.confirmService.confirm({
      message: 'Sind Sie sicher, dass Sie den gewählten Auftrag stornieren möchten?',
      header: 'Stornieren',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if(state.includes('CANCELED')){
          this.showAlreadyStorno();
        }else{
          this.stornoShipment(trackingNumber, connector);
        }
        this.stornoMsg = [{severity:'info', summary:'Confirmed', detail:'You have accepted'}];
      },
      reject: () => {
        this.stornoMsg = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
      key: "storno",
    });
  }

  forceStorno(text:string,trackingNumber, connector){
    this.confirmService.confirm({
      message: text+ ' Möchten Sie den Auftrag in der Auftragsübersicht als storniert kennzeichnen?',
      header: 'Stornieren',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.setShipmentCanceled(trackingNumber, connector);
        this.forceStornoMsg = [{severity:'info', summary:'Confirmed', detail:'You have accepted'}];
      },
      reject: () => {
        this.forceStornoMsg = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      },
      key: "stornoForce",
    });
  }


  labelConfirm(trackingNumber){
    if(trackingNumber == null){
      this.showLabelError("Die Sendung verfügt über keine Trackingnummer. Zuordnung zu labels kann nicht erfolgen!")
    }else{
      this.confirmService.confirm({
        message: `Verfügbare Labels werden geladen! Alle Labels für diese Trackingnummer ${trackingNumber} Herunterladen?`,
        header: 'Labeldruck',
        icon: 'pi pi-print',
        accept: () => {
          this.getAllLabels(trackingNumber);
          this.labelMsg = [{severity:'info', summary:'Confirmed', detail:'You have selected All'}];
        },
        reject: () => {
          this.labelMsg = [{severity:'info', summary:'Rejected', detail:'Custom select'}];
        },
        key: "labelPrint",
      });
    }

  }




  showAlreadyStorno(){this.messageService.add({severity: 'warn', summary: 'Meldung', detail: 'Die Sendunge wurde bereits Storniert!', life: 10000, key: 'alreadyStorno'})}

  showStornoSuccess(text: string){this.messageService.add({severity: 'success', summary:'Erfolgreich', detail: text, life: 10000, key: 'stornoSuccess'});}

  showStornoWarning(text: string){this.messageService.add({severity: 'warn', summary: 'Warnung', detail: text, life: 10000, key: 'stornoWarning'});}

  showStornoError(error: string){this.messageService.add({severity: 'error', summary: 'Fehler', detail: error, life: 10000, key: 'stornoError'});}


  showLabelSuccess(){this.messageService.add({severity: 'success', summary:'Erfolgreich', detail: 'Labels wurden heruntergeladen!', life: 10000, key: 'labelSuccess'});}

  showLabelWarning(text: string){this.messageService.add({severity: 'warn', summary: 'Warnung', detail: text, life: 10000, key: 'labelWarning'});}

  showLabelError(error: string){this.messageService.add({severity: 'error', summary: 'Fehler', detail: error, life: 10000, key: 'labelError'});}



  stornoShipment(shipmentId: string, connector: string){
    this.changeLoading();
    this.orderService.cancelingShipment(shipmentId, connector).pipe(finalize(()=>{
      this.getSortedOrders();
    })).subscribe(data =>{
      if(data.success){
        this.showStornoSuccess(data.responseMessage);
      }else{
        this.forceStorno(data.responseMessage,shipmentId, connector);
        this.showStornoWarning(data.responseMessage);
      }
      this.changeLoading();
    }, error => {
      this.showStornoError(error.status + ' : ' + error.error);
      this.changeLoading();
    });
  }


  setShipmentCanceled(shipmentId: string, connector: string){
    this.changeLoading();
    this.orderService.setShipmentAsCanceled(shipmentId, connector).pipe(finalize(()=>{
      this.getSortedOrders();
    })).subscribe(data =>{
      for(let send of this.sendungen){

        if(send.trackingNumber === data.trackingNumber){
          send.canceled = data.canceled;
        }
      }
      this.changeLoading();
    }, error => {
      this.showStornoError(error.status + ' : ' + error.error);
      this.changeLoading();
    });
  }


  changeLoading(){setTimeout(() => {this.loading = !this.loading;}, 100);}


  updateTotalCount(total: number){this.totalCount = total;}

/*
  sortForDate() {
    let columnToSort:string = "shipTimestamp";

    let sortOrder = -1;

    let value: string;
    let value2: string;

    this.sendungen = this.datasource.sort((current, next)=> {

      value = current[columnToSort] as string;
      value2 = next[columnToSort] as string;

      if (value == null && value2 != null)
        return -1;
      else if (value != null && value2 == null)
        return 1;
      else if (value == null && value2 == null)
        return 0;

      if(sortOrder>0) {
        return value.toString().localeCompare(value2);
      }else  {
        return value2.toString().localeCompare(value);
      }
    });
  }
 */


  updateRowGroupMetadata(){
    this.rowGroupMetadata = {};
    if(this.sendungen){
      for (let i = 0; i < this.sendungen.length; i++){
        let rowData = this.sendungen[i];
        let shipTimestamp = rowData.shipTimestamp;
        if(i == 0){
          this.rowGroupMetadata[shipTimestamp] = {index: this.first, size: 1};
        }else{
          let previousRowData = this.sendungen[i - 1];
          let previousRowGroup = previousRowData.shipTimestamp;
          if(shipTimestamp == previousRowGroup){
            this.rowGroupMetadata[shipTimestamp].size++;

          }else{
            this.rowGroupMetadata[shipTimestamp] = {index: this.first+i, size: 1};

          }
        }
      }
    }
    this.lazyLoading = false;
  }


  activateSearch(){
    this.suche = !this.suche;

    if(this.suche){
      this.searchtext = " Filter ausblenden";
    }else{
      this.searchtext = " Filter einblenden"
    }
  }

  resetSearch(){
    /*
    this.tableComponent.clear();
    this.getSortedOrders();
    this.activateSearch();

     */
    location.reload();
  }


  getSortedOrders(){
    this.changeLoading();

    let page: number;
    let order: string;

    if(this.first == 0){
      page = 0;
    }else{
      page = (this.first/this.rows);
    }

    if(this.sortOrder == 1){
      order = 'asc';
    }else{
      order= 'desc';
    }
    if(this.sortField != undefined){
      this.orderService.getSortedOrders(page, this.rows, this.sortField, order).subscribe(data => {
        this.updateTotalCount(data.totalElements);
        this.formatDateAndState(data);
        this.datasource = this.sendungen;
       // this.updateRowGroupMetadata();
        this.changeLoading();
      });
    }else{
      this.orderService.getSortedOrders(page, this.rows, 'id', 'desc').subscribe(data => {
        this.updateTotalCount(data.totalElements);
        this.formatDateAndState(data);
        this.datasource = this.sendungen;
       // this.sortForDate();
        this.changeLoading();
      },
      error => {
        console.log("Something Went Wrong Sorting Orders: ", error);
      });
    }
  }

  getFilteredOrders(fieldName: string, value: string){
    this.changeLoading();

    let page: number;
    let order: string;

    if(this.first == 0){
      page = 0;
    }else{
      page = (this.first/this.rows);
    }

    if(this.sortOrder == 1){
      order = 'asc';
    }else{
      order= 'desc';
    }

    if(fieldName.includes('state')){
      fieldName = 'canceled';
    }

    this.orderService.getFilteredOrders(page, this.rows, fieldName, value).pipe(finalize(()=>{
      this.datasource = this.sendungen;
      //this.updateRowGroupMetadata();
      this.changeLoading();
    })).subscribe(data=>{
      this.updateTotalCount(data.totalElements);
      this.formatDateAndState(data);
    })

  }

  getFilteredAndSortedOrders(fieldName: string, value: string){
    this.changeLoading();

    let page: number;
    let order: string;

    if(this.first == 0){
      page = 0;
    }else{
      page = (this.first/this.rows);
    }

    if(this.sortOrder == 1){
      order = 'asc';
    }else{
      order= 'desc';
    }

    if(fieldName.includes('state')){
      fieldName = 'canceled';
    }

    this.orderService.getFilteredAndSortedOrders(page, this.rows, this.sortField, order, fieldName, value).pipe(finalize(()=>{
      this.datasource = this.sendungen;
      this.updateRowGroupMetadata();
      this.changeLoading();
    })).subscribe(data=>{
      this.updateTotalCount(data.totalElements);
      this.formatDateAndState(data);
    })

  }



  formatDateAndState(data: any){
    let date = new Date();
    this.sendungen = [];
    for (let i = 0; i < data.content.length; i++) {
      this.sendungen = [...this.sendungen, data.content[i]];
    }
    for (let i = 0; i < this.sendungen.length; i++) {
      if(new Date(this.sendungen[i].pickUpDate) < date){
        this.sendungen[i].inThePast = true;
      }
      this.sendungen[i].shipTimestamp = formatDate(new Date(this.sendungen[i].shipTimestamp).toDateString(), this.format, 'en_DE');
      this.sendungen[i].pickUpDate = formatDate(new Date(this.sendungen[i].pickUpDate).toDateString(), this.format, 'en_DE');
      this.sendungen[i].deliveryDate = formatDate(new Date(this.sendungen[i].deliveryDate).toDateString(), this.format, 'en_DE');
      this.sendungen[i].latestPickupDate = formatDate(new Date(this.sendungen[i].latestPickupDate).toDateString(), this.format, 'en_DE');
      this.sendungen[i].latestDeliveryDate = formatDate(new Date(this.sendungen[i].latestDeliveryDate).toDateString(), this.format, 'en_DE');
      if(this.sendungen[i].canceled){
        this.sendungen[i].state = 'CANCELED';
      }else{
        this.sendungen[i].state = 'ORDERED';
      }
    }
  }


  getLabels(trackingNumber) {
    this.orderService.getLabels(trackingNumber).subscribe(data => {
      this.labelDocuments = data;
      if(this.labelDocuments != undefined){
        for(let sendung of this.sendungen){
          if(sendung.trackingNumber != null && sendung.trackingNumber.includes(trackingNumber)){
            for(let label of this.labelDocuments){
              this.createAndDownloadBlobFile(label.content, label.filename, trackingNumber);
            }
            if(sendung.canceled){
              this.showLabelWarning('Die Sendung wurde bereits storniert!');
            }else{
              this.showLabelSuccess();
            }
          }
        }
      }else{
        this.showLabelError("No Labels found!")
      }
    },
    error => {
      this.showLabelError(error);
    });
  }


  getAllLabels(trackingNumber){
    this.getLabels(trackingNumber);
  }


  base64ToArrayBuffer(base64) {
    console.log("RAW:", base64);
    const binaryString = window.atob(atob(base64)); // Comment this if not using base64
    console.log("Base64 Encoded: ", binaryString);
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }


  createAndDownloadBlobFile(body, filename, trackingNumber: string) {
    const blob = new Blob([this.base64ToArrayBuffer(body)]);
    let name = '';
    if(filename != null){
      let parsedFilename: string[] = filename.split(".");
      name = parsedFilename[0]+'.pdf';
    }else{
      name = trackingNumber+'.pdf';
    }

    if (navigator.msSaveBlob) {
      console.log("navigator.msSaveBlob - true")
      // IE 10+

      navigator.msSaveBlob(blob, name);
    } else {
      const link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', name);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }


  isEmptyObject(obj){return (obj && (Object.keys(obj).length === 0));}

  getNameOfFieldToFilter(obj){
    return Object.keys(obj)[Object.keys(obj).length-1];
  }


  setLocalValues(event: LazyLoadEvent){
    this.first = event.first;
    this.rows = event.rows;
    if(event.sortField == 'state'){
      this.sortField = 'canceled';
    }else{
      this.sortField = event.sortField;
    }
    this.sortOrder = event.sortOrder;
  }



  loadLazy(event: LazyLoadEvent) {
    this.lazyLoading = true;
    if(!this.isEmptyObject(event.filters)){
      this.setLocalValues(event);
      var fieldName:string = this.getNameOfFieldToFilter(event.filters);
      var value:string = event.filters[fieldName].value;
      if (event.sortField != undefined) {
        this.getFilteredAndSortedOrders(fieldName, value);
      }else{
        this.getFilteredOrders(fieldName, value);
      }

    } else if (event.sortField != undefined) {
      this.setLocalValues(event);
      this.getSortedOrders();
    }
    if (event.sortField === undefined && this.isEmptyObject(event.filters)) {
      this.setLocalValues(event);
      this.getSortedOrders();
    }

    this.lazyLoading = false;
  }


  findCarrierPictureForOverview(carrier: string):string{

    var imagePath: string;
    var carrierToCompare = carrier.toUpperCase();

    switch (carrierToCompare){

      case 'FEDEX':
        imagePath = "assets/images/logos/FEDEX_M.png";
        break;

      case 'TNT':
        imagePath = "assets/images/logos/TNT_M.png";
        break;

      case 'DHLEXPRESS':
        imagePath = "assets/images/logos/DHLEXPRESS_M.png";
        break;

      case 'UPS':
        imagePath = "assets/images/logos/UPS_M.png";
        break;

      default:
        imagePath = "assets/images/logos/EXTERN_M.png";
    }
    return imagePath;
  }

  ownFilter(value: any, field: any, matchMode: any){
    this.tableComponent.clear();
    this.tableComponent.filter(value, field, matchMode);
  }

}
