<div class="topbar clearfix">
    <a class="morpheus-logo-link" href="#">
        <img src="assets/layout/images/morpheus-logo-bluegrey.svg" class="morpheus-logo" />
        <span class="morpheus-logo-text">
           Cloud-Carrier
        </span>
    </a>
    <a id="topbar-menu-button" href="#" (click)="app.onTopbarMenuButtonClick($event)">
        <i class="pi pi-bars"></i>
    </a>

    <ul class="topbar-menu fadeInDown" [ngClass]="{'topbar-menu-visible': app.topbarMenuActive}">
        <li #search class="search-item" [ngClass]="{'active-topmenuitem':app.activeTopbarItem === search}"
            (click)="app.onTopbarSearchItemClick($event)">

        </li>

        <!--li #messages [ngClass]="{'active-topmenuitem':app.activeTopbarItem === messages}">
            <a href="#" (click)="app.onTopbarItemClick($event,messages)">
                <i class="topbar-icon animated swing pi pi-fw pi-envelope"></i>
                <span class="topbar-badge">5</span>
                <span class="topbar-item-name">Messages</span>
            </a>
            <ul class="fadeInDown">
                <li role="menuitem">
                    <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                        <img src="assets/layout/images/avatar1.png" width="35"/>
                        <span>Give me a call</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                        <img src="assets/layout/images/avatar2.png" width="35"/>
                        <span>Sales reports attached</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                        <img src="assets/layout/images/avatar3.png" width="35"/>
                        <span>About your invoice</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                        <img src="assets/layout/images/avatar2.png" width="35"/>
                        <span>Meeting today at 10pm</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                        <img src="assets/layout/images/avatar4.png" width="35"/>
                        <span>Out of office</span>
                    </a>
                </li>
            </ul>
        </li-->
        <!--li #settings [ngClass]="{'active-topmenuitem':app.activeTopbarItem === settings}">
            <a href="#" (click)="app.onTopbarItemClick($event,settings)">
                <i class="topbar-icon pi pi-fw pi-sliders-h"></i>
                <span class="topbar-item-name">Settings</span>
            </a>
            <ul class="fadeInDown">
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="pi pi-fw pi-palette"></i>
                        <span>Change Theme</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="pi pi-fw pi-star"></i>
                        <span>Favorites</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="pi pi-fw pi-lock"></i>
                        <span>Lock Screen</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="pi pi-fw pi-image"></i>
                        <span>Wallpaper</span>
                    </a>
                </li>
            </ul>
        </--li-->
        <li #profile class="profile-item" [ngClass]="{'active-topmenuitem':app.activeTopbarItem === profile}">
            <a href="#" (click)="app.onTopbarItemClick($event,profile)">
                <div class="profile-image">
                    <img src="assets/layout/images/default_profile_picture.png">
                </div>
                <span class="profile-text">Profile</span>
            </a>

            <ul class="fadeInDown">
                <!--<li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="pi pi-fw pi-user"></i>
                        <span>Profile</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="pi pi-fw pi-lock"></i>
                        <span>Privacy</span>
                    </a>
                </li>-->
                <li role="menuitem">
                    <a href="#" [routerLink]="['/serviceprovider']">
                        <i class="pi pi-fw pi-users"></i>
                        <span>Service Provider</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a (click)="app.logout()">
                        <i class="pi pi-fw pi-sign-out"></i>
                        <span>Logout</span>
                    </a>
                </li>
            </ul>
        </li>
    </ul>
</div>
