import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {OauthService} from '../security/oauth.service';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';

/**
 * this guard observes urls for authorization through an authorization service
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService extends KeycloakAuthGuard{
  constructor(
      protected readonly router: Router,
      protected readonly keycloak: KeycloakService,
      protected readonly oauthService:OauthService
  ) {
    super(router, keycloak);
  }

  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (!this.authenticated) {
        this.keycloakAngular.login();
        return;
      }
      console.log('role restriction given at app-routing.module for this route', route.data.roles);
      console.log('User roles coming after login from keycloak :', this.roles);
      const requiredRoles = route.data.roles;
      let granted = false;
      if (!requiredRoles || requiredRoles.length === 0) {
        granted = true;
      } else {
        for (const requiredRole of requiredRoles) {
          if (this.roles.indexOf(requiredRole) > -1) {
            granted = true;
            break;
          }
        }
      }

      if(granted === false) {
        this.router.navigate(['/']);
      }
      resolve(granted);

    });
  }
}
