import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs/index";

@Injectable({
  providedIn: 'root'
})
export class CarrierMappingService {

  carrier = environment.carrierMapping.carrier;

  constructor(private httpClient: HttpClient) { }



  getCarriersWithMapping(): Observable<string[]>{
    return this.httpClient.get<string[]>(this.carrier);
  }

}
