import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {ServiceOption} from "../model/constants";

export const GET = 1;

@Injectable()
export class ServiceOptionStore {


    serviceOptions: any;

    item: BehaviorSubject<ServiceOption[]> = new BehaviorSubject<ServiceOption[]>(null);

    constructor() {
    }



    dispatch(action) {

            this.serviceOptions = this.reduce(action);
            this.item.next(this.serviceOptions);
    }

    reduce(action) {
        switch (action.type) {
            case GET:
                return [...action.data];
        }
    }
}
