import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Store} from './generic.store';
import {Result, ResultList} from './result.model';
import {Shipment} from '../model/shipment.model';
import {SearchStore} from '../search/search.store';
import {CHANGED, CREATED, ShipStore} from '../ship/ship.store';
import {ResultListsStore} from './result.list.store';


interface ExceptionList {
    carrier: string;
    exception: string;
}

/**
 * This component renders rates search results
 */
@Component({
    selector: 'logle-results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {

    @Input()
    showPickUp: boolean;
    @Input()
    contentDisabled: boolean;
    @Output()
    carrierSelected = new EventEmitter<boolean>();
    @Output()
    resetSearch = new EventEmitter<boolean>();

    private shipment: Shipment = {
        carrier: "",
        carrierName: "",
        deliveryDate: undefined,
        latestDeliveryDate: undefined,
        latestPickupDate: undefined,
        packageCount: 0,
        packages: [],
        pickUpDate: undefined,
        shipTo: undefined,
        pickupType: '',
        serviceDescription: "",
        serviceType: "",
        shipFrom: undefined,
        shipTimestamp: undefined,
        shipper: undefined,
        shippingChargesPayment: undefined
    }

    resultSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
    searchSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
    searchSubscription: Subscription;
    exceptionList: ExceptionList[] = [];
    searchResults: ResultList = new ResultList()
    columns: any[];
    searchParams: any;
    foundExceptions = false;

    constructor(private resultListStore: ResultListsStore,
                private resultStore: Store,
                private shipStore: ShipStore,
                private searchStore: SearchStore) {
    }

    ngOnInit() {
        // Result
        // this.resultSubject = this.resultStore.item.subscribe(next=> );
        this.resultListStore.hasChanged().subscribe({
            next: flag => {
                if (flag === true) {
                    console.log('results.component: updating data')
                    //his.searchSubject.next(this.resultListStore.getData());
                    this.searchResults = this.resultListStore.getData();
                }
            }
        });

        // this.searchSubject = this.searchStore.item;
        /*this.resultStore.item.subscribe(next => {
            console.log("results component: incomming result > " , next)
            this.searchResults = next});*/
        this.createColumns();
        this.searchSubscription = this.searchSubject.subscribe(searchParams => {
            this.searchParams = searchParams;
            // console.log("Search Params", this.searchParams);
            this.updateSearchPriceIrrelavantValues(this.searchParams);
        })
        this.searchStore.item.subscribe(value => {
            console.log('Search Params from Search Detail Component',JSON.stringify(value));
            this.searchParams = value
        })

    }

    findExceptionsOnRateSearch(resultList: ResultList) {
        console.log('found errors in result: ');
        console.log(resultList)
        for (const errror of resultList.errors) {
            const ex: ExceptionList = {carrier: errror.carrier, exception: errror.error.toString()};
            this.exceptionList.push(ex);
            this.foundExceptions = true;
        }
        console.log('foundExceptions: ' + this.foundExceptions);
    }

    ngOnDestroy() {
        console.log('ngOnDestroy');
        this.searchSubscription.unsubscribe();
    }

    createColumns() {
        this.columns = [
            {field: 'carrier', header: 'Carrier'},
            {field: 'serviceDescription', header: 'Produkt'},
            {field: 'pickupDate', header: 'Abholung'},
            {field: 'deliveryDate', header: 'Zustellung'},
            {field: 'rateCharge', header: 'Netto Preis'}
        ]
    }

    createOrder(index: Result) {
        console.log('this.searchResults');
        console.log(this.searchResults);
        console.log('index ' + JSON.stringify(index));
        console.log(JSON.stringify(this.searchParams));
        this.shipment = {
            packageCount: this.searchParams.packageDetails.length,
            labelSpecifications: undefined,
            shippingChargesPayment: undefined,
            carrier: index.carrierName,
            carrierName: index.carrierName,
            serviceType: index.serviceType,
            serviceDescription: index.serviceDescription,
            pickUpDate: this.searchParams.pickUpDate,
            deliveryDate: this.searchParams.deliveryDate,
            latestPickupDate: this.searchParams.latestPickupDate,
            latestDeliveryDate: this.searchParams.latestDeliveryDate,
            shipFrom: this.searchParams.shipFrom,
            shipper: this.searchParams.shipper,
            shipTo: this.searchParams.shipTo,
            pickupType: this.searchParams.pickupType,
            shipTimestamp: this.searchParams.deliveryDate,
            packages: this.searchParams.packageDetails,
            rateCharge : index.netCharge
        }
        // this.mapPackageTypes(this.shipment.packages);
        this.shipStore.dispatch({type: CREATED, data: this.shipment});
        this.carrierSelected.emit(true);
    }


    mapPackageTypes(packages: any[]) {
        console.log('MAPPING PACKAGES!!!!!', packages);

        let isSet = false;
        for (const pack of packages) {
            isSet = false;
            if (pack.additionalTypes != null || pack.additionalTypes !== undefined) {
                for (const type of pack.additionalTypes) {
                    console.log('TYPE: ', type + ' - ' + 'CARRIER: ' + this.shipment.carrier);
                    if (type.includes(this.shipment.carrier.toLowerCase() + ':')) {
                        const val: string[] = type.split(':');
                        console.log('VAL: ', val);
                        pack.type = val[1];
                        isSet = true;
                        if (val[2] != null || val[2] !== undefined) {
                            pack.code = val[2];
                        }
                    }
                }
            }

            if (!isSet) {
                pack.type = 'Eigene Verpackung/ Own packaging';
            }
        }
        console.log('MAPPING PACKAGES!!!!!', packages);
        this.shipment.packages = packages;

    }

// todo rename function
    updateSearchPriceIrrelavantValues(searchParam: any) {
        // console.log(searchParam);
        if (!this.showPickUp) {
            if (this.shipment.shipFrom && searchParam.shipper) {
                this.shipment.shipFrom.name = searchParam.shipper.companyName;
                this.shipment.shipFrom.houseNr = searchParam.shipper.houseNr;
                this.shipment.shipFrom.personName = searchParam.shipper.personName;
                this.shipment.shipFrom.emailAdress = searchParam.shipper.emailAdress;
                this.shipment.shipFrom.phoneNumber = searchParam.shipper.phoneNumber;
                this.shipment.shipFrom.street = searchParam.shipper.street;
            }
            if (this.shipment.shipper && searchParam.shipper) {
                this.shipment.shipper.name = searchParam.shipper.companyName;
                this.shipment.shipper.houseNr = searchParam.shipper.houseNr;
                this.shipment.shipper.personName = searchParam.shipper.personName;
                this.shipment.shipper.emailAdress = searchParam.shipper.emailAdress;
                this.shipment.shipper.phoneNumber = searchParam.shipper.phoneNumber;
                this.shipment.shipper.street = searchParam.shipper.street;
            }
        } else {
            if (this.shipment.shipFrom && searchParam.pickUp) {
                this.shipment.shipFrom.name = searchParam.pickUp.companyName;
                this.shipment.shipFrom.houseNr = searchParam.pickUp.houseNr;
                this.shipment.shipFrom.personName = searchParam.pickUp.personName;
                this.shipment.shipFrom.emailAdress = searchParam.pickUp.emailAdress;
                this.shipment.shipFrom.phoneNumber = searchParam.pickUp.phoneNumber;
                this.shipment.shipFrom.street = searchParam.pickUp.street;
            }
            if (this.shipment.shipper && searchParam.shipper) {
                this.shipment.shipper.name = searchParam.shipper.companyName;
                this.shipment.shipper.houseNr = searchParam.shipper.houseNr;
                this.shipment.shipper.personName = searchParam.shipper.personName;
                this.shipment.shipper.emailAdress = searchParam.shipper.emailAdress;
                this.shipment.shipper.phoneNumber = searchParam.shipper.phoneNumber;
                this.shipment.shipper.street = searchParam.shipper.street;
            }

        }
        if (this.shipment.shipTo && searchParam.recipient) {
            this.shipment.shipTo.name = searchParam.recipient.companyName;
            this.shipment.shipTo.houseNr = searchParam.recipient.houseNr;
            this.shipment.shipTo.personName = searchParam.recipient.personName;
            this.shipment.shipTo.emailAdress = searchParam.recipient.emailAdress;
            this.shipment.shipTo.phoneNumber = searchParam.recipient.phoneNumber;
            this.shipment.shipTo.street = searchParam.recipient.street;
        }
        this.shipStore.dispatch({type: CHANGED, data: this.shipment});
    }

    goToInit() {
        this.resetSearch.emit(true);
    }

    getShipment() {
        return this.shipment;
    }
}
