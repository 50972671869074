import {Component, OnInit} from '@angular/core';
import {ServiceProviderService} from "../service/provider/service-provider.service";
import {TenantConnectorPrefs} from "../../model/TenantConnectorPrefs/tenant-connector-prefs";
import {MessageService, SelectItem} from "primeng/api";
import {ServiceProviderEditComponent} from "../service-provider-edit/service-provider-edit.component";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {Router} from "@angular/router";
import {AllConnectorPrefs} from "../../model/AllConnectorPrefs/all-connector-prefs";
import {TenantConnectorPrefDTO} from "../../model/TenantConnectorPrefDTO/tenant-connector-pref-dto";

@Component({
    selector: 'logle-service-provider-selection',
    templateUrl: './service-provider-selection.component.html',
    styleUrls: ['./service-provider-selection.component.scss']
})
export class ServiceProviderSelectionComponent implements OnInit {

    allConnectorPrefs: AllConnectorPrefs[] = [];
    sortOptions: SelectItem[];
    sortOrder: number;
    sortField: string;

    selectedConnector: AllConnectorPrefs;
    selectedConnectorForTenant: TenantConnectorPrefs = new TenantConnectorPrefs();
    ref: DynamicDialogRef;

    constructor(private router: Router, private providerService: ServiceProviderService,
                public dialogService: DialogService, public messageService: MessageService) {
    }

    ngOnInit(): void {
        this.providerService.getAllConnectors().subscribe(data => {
            console.log("getAllConnectorsData: ", data);
            this.allConnectorPrefs = data;
        });
    }

    findCarrierPictureForOverview(carrier: string): string {

        if(carrier != undefined){
            var imagePath: string;
            var carrierToCompare = carrier.toUpperCase();

            switch (carrierToCompare) {

                case 'FEDEX':
                    imagePath = "assets/images/logos/FEDEX_M.png";
                    break;

                case 'TNT':
                    imagePath = "assets/images/logos/TNT_M.png";
                    break;

                case 'DHLEXPRESS':
                    imagePath = "assets/images/logos/DHLEXPRESS_M.png";
                    break;

                case 'UPS':
                    imagePath = "assets/images/logos/UPS_M.png";
                    break;

                default:
                    imagePath = "assets/images/logos/EXTERN_M.png";
            }
            return imagePath;
        }
    }


    editDialog() {
        this.connectorToTenantConnector();
        this.ref = this.dialogService.open(ServiceProviderEditComponent, {
            data: {
                connector: this.tenantConnectorToDTOConverter(true, true, this.selectedConnectorForTenant)
            },
            header: 'Edit Provider',
            width: '40%',
            contentStyle: {"max-height": "1000px", "overflow": "hidden", "text-align": "center"},
            baseZIndex: 10000,
            closable: false
        });

        this.ref.onClose.subscribe(data => {
            console.log("EDIT DIALOG ONCLOSE!!!!!!!!!!!!", data);
            if(data === 'Abbrechen'){
                this.selectedConnector = new AllConnectorPrefs();
                this.selectedConnectorForTenant = new TenantConnectorPrefs();
            }
        });
    }


    connectorToTenantConnector() {
        this.selectedConnectorForTenant = new TenantConnectorPrefs();
        this.selectedConnectorForTenant.tenant = this.selectedConnector.tenant;
        this.selectedConnectorForTenant.carrier = this.selectedConnector.connectorName;
        this.selectedConnectorForTenant.active = true;
        for(let pref of this.selectedConnector.preferences_key){
          this.selectedConnectorForTenant.preferences[pref] = "";
        }
    }

    tenantConnectorToDTOConverter(newConnector: boolean, editConnector: boolean, connector: TenantConnectorPrefs): TenantConnectorPrefDTO{
        let connectorDTO = new TenantConnectorPrefDTO();
        connectorDTO.newConnector = newConnector;
        connectorDTO.editConnector = editConnector;
        connectorDTO.tenantConnectorPref = connector;
        return connectorDTO;
    }


}
