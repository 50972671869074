<div class="layout-sidebar" style="overflow: hidden;">
    <div class="layout-tabmenu">
        <ul class="layout-tabmenu-nav">
            <li>
                <a class="morpheus-logo-link" [routerLink]="['']" ><i
                        class="pi pi-search"></i>
                    <!--img src="assets/layout/images/morpheus-logo-{{app.theme}}.svg" class="morpheus-logo" /-->
                </a>
            </li>
            <li>
                <a class="morpheus-logo-link" [routerLink]="['orderview']">
                    <i
                            class="pi pi-list"></i>
                    <!--img src="assets/layout/images/morpheus-logo-{{app.theme}}.svg" class="morpheus-logo" /-->
                </a>

            </li>
            <!--
            <li [ngClass]="{'active-item':app.activeTabIndex === 0}">
                <a href="#" class="tabmenuitem-link" (click)="app.onTabClick($event, 0)" pRipple><i
                        class="pi pi-folder-open"></i></a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Features</div>
                </div>
            </li>
            <li [ngClass]="{'active-item':app.activeTabIndex === 1}">
                <a href="#" class="ripplelink tabmenuitem-link" (click)="app.onTabClick($event, 1)" pRipple><i
                        class="pi pi-inbox"></i></a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Inbox</div>
                </div>
            </li>
            <li [ngClass]="{'active-item':app.activeTabIndex === 2}">
                <a href="#" class="ripplelink tabmenuitem-link" (click)="app.onTabClick($event, 2)" pRipple><i
                        class="pi pi-calendar"></i></a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Calendar</div>
                </div>
            </li>
            <li [ngClass]="{'active-item':app.activeTabIndex === 3}">
                <a href="#" class="ripplelink tabmenuitem-link" (click)="app.onTabClick($event, 3)" pRipple><i
                        class="pi pi-image"></i></a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Projects</div>
                </div>
            </li>
            <li [ngClass]="{'active-item':app.activeTabIndex === 4}">
                <a href="#" class="ripplelink tabmenuitem-link" (click)="app.onTabClick($event, 4)" pRipple><i
                        class="pi pi-chart-bar"></i></a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Updates</div>
                </div>
            </li>
            -->
        </ul>
        <!--
        <div class="layout-tabmenu-contents">
            <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': app.activeTabIndex === 0}">
                <div class="layout-submenu-title clearfix">
                    <span>FEATURES</span>
                </div>
                <app-sidebarTabContent>
                    <app-menu></app-menu>
                </app-sidebarTabContent>
            </div>
            <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': app.activeTabIndex === 1}">
                <div class="layout-submenu-title clearfix">
                    <span>INBOX</span>
                </div>
                <app-sidebarTabContent>
                    <div class="inbox-tab">
                        <ul>
                            <li class="clearfix">
                                <img src="assets/layout/images/avatar1.png" alt="" width="45">
                                <span class="name">Joshua Williams</span>
                                <span class="message">Reports attached</span>
                            </li>
                            <li class="clearfix">
                                <img src="assets/layout/images/avatar1.png" alt="" width="45">
                                <span class="name">Joshua Williams</span>
                                <span class="message">About the meeting</span>
                            </li>
                            <li class="clearfix">
                                <img src="assets/layout/images/avatar2.png" alt="" width="45">
                                <span class="name">Emily Clark</span>
                                <span class="message">TODO Update</span>
                            </li>
                            <li class="clearfix">
                                <img src="assets/layout/images/avatar3.png" alt="" width="45">
                                <span class="name">Tim Johnson</span>
                                <span class="message">Expense Reports</span>
                            </li>
                            <li class="clearfix">
                                <img src="assets/layout/images/avatar4.png" alt="" width="45">
                                <span class="name">David Stark</span>
                                <span class="message">Rating Reviews</span>
                            </li>
                        </ul>
                        <div class="inbox-labels">
                            <span>Labels</span>
                            <ul>
                                <li class="clearfix">
                                    <span class="inbox-label">Work</span>
                                    <span class="inbox-label-badge">4</span>
                                </li>
                                <li class="clearfix">
                                    <span class="inbox-label">Personel</span>
                                    <span class="inbox-label-badge">2</span>
                                </li>
                                <li class="clearfix">
                                    <span class="inbox-label">Shared</span>
                                    <span class="inbox-label-badge">3</span>
                                </li>
                                <li class="clearfix">
                                    <span class="inbox-label">General</span>
                                    <span class="inbox-label-badge">5</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </app-sidebarTabContent>
            </div>
            <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': app.activeTabIndex === 2}">
                <div class="layout-submenu-title clearfix">
                    <span>CALENDAR</span>
                </div>
                <app-sidebarTabContent>
                    <div class="calendar-tab">
                        <ul>
                            <li class="clearfix">
                                <div class="calendar-event-date">
                                    <span>21</span>
                                    <span>OCT</span>
                                </div>
                                <div class="calendar-event-detail">
                                    <span class="calendar-event-title">Project Meeting</span>
                                    <i class="pi pi-map-marker"></i>
                                    <span class="calendar-event-location">Chicago</span>
                                </div>
                            </li>
                            <li class="clearfix">
                                <div class="calendar-event-date">
                                    <span>13</span>
                                    <span>NOV</span>
                                </div>
                                <div class="calendar-event-detail">
                                    <span class="calendar-event-title">Presentation 1</span>
                                    <i class="pi pi-map-marker"></i>
                                    <span class="calendar-event-location">New York</span>
                                </div>
                            </li>
                            <li class="clearfix">
                                <div class="calendar-event-date">
                                    <span>01</span>
                                    <span>DEC</span>
                                </div>
                                <div class="calendar-event-detail">
                                    <span class="calendar-event-title">Presentation 2</span>
                                    <i class="pi pi-map-marker"></i>
                                    <span class="calendar-event-location">New York</span>
                                </div>
                            </li>
                            <li class="clearfix">
                                <div class="calendar-event-date">
                                    <span>25</span>
                                    <span>DEC</span>
                                </div>
                                <div class="calendar-event-detail">
                                    <span class="calendar-event-title">Presentation 3</span>
                                    <i class="pi pi-map-marker"></i>
                                    <span class="calendar-event-location">San Diego</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </app-sidebarTabContent>
            </div>
            <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': app.activeTabIndex === 3}">
                <div class="layout-submenu-title clearfix">
                    <span>PROJECTS</span>
                </div>
                <app-sidebarTabContent>
                    <div class="projects-tab">
                        <ul>
                            <li class="clearfix">
                                <i class="pi pi-github"></i>
                                <span class="project-title">GitHub Tasks</span>
                                <span>3 open, 4 closed</span>
                                <div class="project-progressbar">
                                    <div class="project-progressbar-value" style="width: 50%"></div>
                                </div>
                            </li>
                            <li class="clearfix">
                                <i class="pi pi-video"></i>
                                <span class="project-title">YouTube Videos</span>
                                <span>12 new, 2 subscribers</span>
                                <div class="project-progressbar">
                                    <div class="project-progressbar-value" style="width: 20%"></div>
                                </div>
                            </li>
                            <li class="clearfix">
                                <i class="pi pi-twitter"></i>
                                <span class="project-title">Twitter Posts</span>
                                <span>24 new messages</span>
                                <div class="project-progressbar">
                                    <div class="project-progressbar-value" style="width: 65%"></div>
                                </div>
                            </li>
                            <li class="clearfix">
                                <i class="pi pi-facebook"></i>
                                <span class="project-title">Facebook Messages</span>
                                <span>8 total, 4 unread</span>
                                <div class="project-progressbar">
                                    <div class="project-progressbar-value" style="width: 80%"></div>
                                </div>
                            </li>
                            <li class="clearfix">
                                <i class="pi pi-user"></i>
                                <span class="project-title">Linkedin Contacts</span>
                                <span>90 total</span>
                                <div class="project-progressbar">
                                    <div class="project-progressbar-value" style="width: 90%"></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </app-sidebarTabContent>
            </div>
            <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': app.activeTabIndex === 4}">
                <div class="layout-submenu-title clearfix">
                    <span>UPDATES</span>
                </div>
                <app-sidebarTabContent>
                    <div class="update-tab">
                        <p>Apple consuption count distribution to population (in percentage).</p>
                        <div class="percentage-indicator">
                            <span>Britain</span>
                            <span>84%</span>
                        </div>
                        <div class="progress-bar">
                            <div class="progress-bar-indicator-1"></div>
                        </div>
                        <div class="percentage-indicator">
                            <span>Germany</span>
                            <span>58%</span>
                        </div>
                        <div class="progress-bar">
                            <div class="progress-bar-indicator-2"></div>
                        </div>
                        <div class="percentage-indicator">
                            <span>Turkey</span>
                            <span>44%</span>
                        </div>
                        <div class="progress-bar">
                            <div class="progress-bar-indicator-3"></div>
                        </div>
                        <div class="percentage-indicator">
                            <span>Slovenia</span>
                            <span>37%</span>
                        </div>
                        <div class="progress-bar">
                            <div class="progress-bar-indicator-4"></div>
                        </div>
                    </div>

                </app-sidebarTabContent>
            </div>
        </div>
    </div>
</div>
-->
    </div>
</div>
