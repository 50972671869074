import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {TenantConnectorPrefs} from "../../model/TenantConnectorPrefs/tenant-connector-prefs";
import {ServiceProviderService} from "../service/provider/service-provider.service";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {MessageService} from "primeng/api";
import {ServiceProviderEditComponent} from "../service-provider-edit/service-provider-edit.component";
import {ServiceProviderSelectionComponent} from "../service-provider-selection/service-provider-selection.component";
import {TenantConnectorPrefDTO} from "../../model/TenantConnectorPrefDTO/tenant-connector-pref-dto";
import {CarrierMappingService} from "../service/carrier-mapping/carrier-mapping.service";

@Component({
  selector: 'logle-service-provider-start',
  templateUrl: './service-provider-start.component.html',
  styleUrls: ['./service-provider-start.component.scss']
})
export class ServiceProviderStartComponent implements OnInit {



  tenantConnectorPrefs: TenantConnectorPrefs[] = [];
  tenant: string = "";

  ref: DynamicDialogRef;
  constructor(private router: Router, private providerService: ServiceProviderService,
              public dialogService: DialogService, public messageService: MessageService,
              private carrierMappingService: CarrierMappingService) { }

  ngOnInit(): void {
    this.getTenantConnectors();
  }


  getTenantName(){
    console.log("HOLE TENANT NAME");
    this.providerService.getTenantName().subscribe(data =>{
      this.tenant = data;
      console.log("Data is set to : ", data);
      console.log("Tenant is set to : ", this.tenant);
    });
  }

  getTenantConnectors(){
    this.providerService.getConnectorsForTenant().subscribe(data =>{

      let temp : TenantConnectorPrefs[] = [];
      temp = data;
      this.tenantConnectorPrefs = this.sortForDeactivated(temp);

    });
  }




  sortForDeactivated(temp: TenantConnectorPrefs[]): TenantConnectorPrefs[]{
    return temp.sort((n1, n2) => {
      if (n1.active) {
        return -1;
      } else {
        return 1;
      }
    });
  }


  openNew() {
    this.ref = this.dialogService.open(ServiceProviderSelectionComponent, {
      header: 'Select Provider',
      width: '40%',
      contentStyle: {"max-height": "550px", "text-align": "center", "height":"550px"},
      baseZIndex: 10000
    });

    this.ref.onClose.subscribe(data =>{
      console.log("OPEN NEW ONCLOSE!!!!!!!!!!!!", data);
      this.getTenantConnectors();
    });
  }

  editDialog(connector: TenantConnectorPrefs){
    this.ref = this.dialogService.open(ServiceProviderEditComponent, {
      data:{
        connector: this.tenantConnectorToDTOConverter(false, true, connector)
      },
      header: 'Edit Provider',
      width: '40%',
      contentStyle: {"max-height": "1000px", "overflow": "hidden", "text-align": "center"},
      baseZIndex: 10000,
      closable: false
    });

    this.ref.onClose.subscribe(data =>{
      console.log("EDIT DIALOG ONCLOSE!!!!!!!!!!!!", data);
      this.getTenantConnectors();
    });
  }


  tenantConnectorToDTOConverter(newConnector: boolean, editConnector: boolean, connector: TenantConnectorPrefs): TenantConnectorPrefDTO{
    let connectorDTO = new TenantConnectorPrefDTO();
    connectorDTO.newConnector = newConnector;
    connectorDTO.editConnector = editConnector;
    connectorDTO.tenantConnectorPref = connector;

    return connectorDTO;
  }

  findCarrierPictureForOverview(carrier: string):string{

    var imagePath: string;
    var carrierToCompare = carrier.toUpperCase();

    switch (carrierToCompare){

      case 'FEDEX':
        imagePath = "assets/images/logos/FEDEX_M.png";
        break;

      case 'TNT':
        imagePath = "assets/images/logos/TNT_M.png";
        break;

      case 'DHLEXPRESS':
        imagePath = "assets/images/logos/DHLEXPRESS_M.png";
        break;

      case 'UPS':
        imagePath = "assets/images/logos/UPS_M.png";
        break;

      default:
        imagePath = "assets/images/logos/EXTERN_M.png";
    }
    return imagePath;
  }



}
