import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs/index";
import {TenantConnectorPrefs} from "../../../model/TenantConnectorPrefs/tenant-connector-prefs";
import {AllConnectorPrefs} from "../../../model/AllConnectorPrefs/all-connector-prefs";

@Injectable({
  providedIn: 'root'
})
export class ServiceProviderService {

  connectorPrefStructure = environment.userSettings.connectorPropsStructure;
  connectorProps = environment.userSettings.connectorProps;
  connectorGetTenantName = environment.userSettings.connectorPropsTenantName;
  allConnectorProps = environment.userSettings.allConnectorProps;

  constructor(private httpClient: HttpClient) { }


  getConnectorStructures():Observable<TenantConnectorPrefs[]>{
    return this.httpClient.get<TenantConnectorPrefs[]>(this.connectorPrefStructure);
  }

  getConnectorsForTenant():Observable<TenantConnectorPrefs[]>{
    return this.httpClient.get<TenantConnectorPrefs[]>(this.connectorProps);
  }

  deactivateConnector(connector: string): Observable<any>{
    return this.httpClient.put<any>(this.connectorProps+`deactivate/${connector}`,{});
  }

  activateConnector(connector: string): Observable<any>{
    return this.httpClient.put<any>(this.connectorProps+`activate/${connector}`,{});
  }

  addConnector(connector: TenantConnectorPrefs):Observable<any>{
    console.log("ADD CONNECTOR CALL: ", connector);
    return this.httpClient.post<any>(this.connectorProps, connector);
  }

  deleteConnector(connector: string): Observable<any>{
    return this.httpClient.delete<any>(this.connectorProps+`${connector}`,{});
  }


  getAllConnectors(): Observable<AllConnectorPrefs[]>{
    return this.httpClient.get<AllConnectorPrefs[]>(this.allConnectorProps);
  }

  getTenantName(): Observable<any>{
    console.log("Starte call");
    return this.httpClient.get<any>(this.connectorGetTenantName);
  }



}
