<div class="layout-wrapper" [ngClass]="{'layout-wrapper-menu-active':sidebarActive,
                                        'layout-overlay-menu':overlay, 'p-input-filled': inputStyle === 'filled', 'p-ripple-disabled': !ripple}">

    <app-topbar></app-topbar>

    <app-sidebar></app-sidebar>

    <div class="layout-content">
        <p-messages></p-messages>
        <div class="layout-main">
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </div>
</div>
