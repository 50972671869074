import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {DateTime} from "luxon";
import {defaultHours, DELIVERY_DATE_OFFSET, LATEST_DATE_OFFSET} from "../searchDetail/search.form.provider";

export class ShippingFormProvider {
    constructor(private formbuilder: FormBuilder) {
    }

    public blub() {
        return this.formbuilder.group({
            carrier: new FormControl<string>(''),
            carrierName: new FormControl<string>(''),
            currency: new FormControl<string>(''),
            rateCharge: new FormControl<number>(0),
            shipTo: this.createAddress(),
            serviceType: new FormControl<string>(''),
            shipFrom: this.createAddress(),
            ...this.createSearchFormDeliveryDates(),
            shipper: this.createAddress(),
            additionalServices: [],
            packages: this.createPackages(),
            pickupType: new FormControl<string>('NONE')
        })
    }

    createAddress() {
        return this.formbuilder.group({
            companyName: new FormControl<string>('', Validators.required),
            name: new FormControl<string>('', Validators.required),
            street: new FormControl<string>('', Validators.required),
            streetNumber: new FormControl<string>('', Validators.required),
            houseNr: new FormControl<string>('', Validators.required),
            postalCode: new FormControl<string>('', Validators.required),
            city: new FormControl<string>('', Validators.required),
            countryCode: new FormControl<string>('', Validators.required),
            cityState: new FormControl<string>(''),
            personName: new FormControl<string>('', Validators.required),
            phoneNumber: new FormControl<string>('', Validators.required),
            emailAddress: new FormControl<string>('', Validators.required),
        })

    }

    createPackages() {
        return this.formbuilder.array([this.formbuilder.group({
            type: new FormControl<string>('', Validators.required),
            code: new FormControl<string>(''),
            additionalTypes: new FormControl<string>(''),
            groupedTypes: this.formbuilder.array(['']),
            weight: new FormControl<number>(0, Validators.required),
            dimension: this.formbuilder.group({
                height: new FormControl<number>(0, Validators.required),
                length: new FormControl<number>(0, Validators.required),
                width: new FormControl<number>(0, Validators.required),

            })
        })])
    }

    createSearchFormDeliveryDates() {
        return {
            deliveryDate: new FormControl<Date>(this.convertToJSDate(this.createDeliveryDate()), Validators.required),
            latestDeliveryDate: new FormControl<Date>(this.convertToJSDate(this.createDeliveryDate()
                .plus({day: DELIVERY_DATE_OFFSET})), Validators.required),
            pickUpDate: new FormControl<Date>(this.convertToJSDate(this.createPickupDate()), Validators.required),
            latestPickupDate: new FormControl<Date>(this.convertToJSDate(this.createPickupDate()
                .plus({day: LATEST_DATE_OFFSET})), Validators.required),
        }
    }

    createDeliveryDate() {
        return DateTime.now().set({
            day: DELIVERY_DATE_OFFSET,
            ...defaultHours
        }).setZone('Europe/Berlin', {keepLocalTime: true})
    }

    createPickupDate() {
        return DateTime.now().set(defaultHours).setZone('Europe/Berlin', {keepLocalTime: true})
    }

    convertToJSDate(dt: DateTime):
        Date {
        return dt.toJSDate();
    }

}
