<div>
    <p-inplace *ngIf="foundExceptions" closable="closable" [style]="{'min-height':'33px'}">
        <ng-template pTemplate="display">
            <div class="p-d-inline-flex p-ai-center">
                <span class="pi pi-table" style="vertical-align: middle"></span>
                <span class="p-ml-2">Siehe Fehlermeldungen</span>
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <p-table [value]="exceptionList">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Kurier</th>
                        <th>Fehlermeldung</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-car>
                    <tr>
                        <td style="font-weight: bold">{{car.carrierName}}</td>
                        <td style="color: red">{{car.exception}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </ng-template>
    </p-inplace>
    <p-table [columns]="columns" [value]="searchResults.carrierResults">

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                    {{col.header}}
                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                ariaLabelDesc="Activate to sort in descending order"
                                ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </th>
                <th>
                    Aktionen
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-index let-columns="columns">
            <tr *ngIf="rowData['netCharge']">
                <td class="{{rowData['carrierName'].toLowerCase()}}">
                    <img src="assets/images/logos/{{rowData['carrierName'].toUpperCase()}}_M.png">
                </td>
                <td>
                    {{rowData['serviceDescription']}}
                </td>
                <td>
                    {{rowData['pickupDate']}}
                </td>
                <td>
                    <p>{{rowData['deliveryDate'] | date:'dd.MM.yyyy HH:mm'}}</p>
                </td>

                <td>
                    {{rowData['netCharge'] | currency: 'EUR'}}
                </td>
                <td>
                    <button pButton label="Auftrag" (click)="createOrder(index)" [disabled]="contentDisabled"></button>

                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-footer>
        <table style="margin-left: auto;">
            <tr>
                <td>
                    <span *ngIf="contentDisabled">Bitte führen Sie die Suche erneut aus.</span>
                    <button pButton class="p-button-danger" label="Neue Suche" (click)="goToInit()"
                            icon="pi pi-search"></button>
                </td>
                <td>
                </td>
            </tr>
        </table>
    </p-footer>
</div>
