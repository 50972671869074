import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {Message} from "primeng/api";


@Injectable()
export class MessageStore {
    message: Message;

    item: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor() {
    }

    dispatch(action: Message) {
        console.log(action);
        this.item.next(action);
    }
}
