<p-messages severity="success" *ngIf="trackingNumber">
    <ng-template pTemplate>
        <button pButton icon="pi pi-envelope" class="p-button-rounded" (click)="downloadLabel()" pTooltip="Label Herunterladen"></button>
        <div class="p-ml-2">Dokumente herunterladen.</div>
        <button pButton style="margin-left: auto;margin-right: 0;" icon="pi pi-times" class="p-button-rounded" (click)="close()" pTooltip="Dokumente nicht herunterladen."></button>
    </ng-template>
</p-messages>
<p-accordion [activeIndex]="activeIndex">

    <p-accordionTab header="Suche" class="full-width" [(selected)]="activeState[0]">
        <logle-search-detail [reset]="reset" [showPickup]="showPickup" (initialized)="initState()" (showPickupChanged)="showPickup" (searchStarted)="startSearch($event)"></logle-search-detail>
    </p-accordionTab>

    <p-accordionTab header="Ergebnisse" style="display: block"  [disabled]="resultDisabled" [(selected)]="activeState[1]">
        <logle-results *ngIf="searchResult" [contentDisabled]="contentDisabled" (resetSearch)="initState()" (carrierSelected)="carrierSelect()"></logle-results>
    </p-accordionTab>

    <p-accordionTab header="Versenden" style="display: block" [disabled]="shippingDisabled" [(selected)]="activeState[2]">
        <logle-ship *ngIf="searchResult" (canceled)="cancelOrder()" (sumbitOrder)="submitOrder()"></logle-ship>
    </p-accordionTab>

</p-accordion>

