// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {KeycloakService} from 'keycloak-angular';

const keycloakConfig = {
    url: '@@host_oauth2@@',
    realm: '@@oauth2_realm@@',
    issuer: '@@host_oauth2@@/realms/@@oauth2_realm@@',
    clientId: '@@client_id@@'
};

export const environment = {
    keycloak: keycloakConfig,
    production: false,
    success: {
        url: '/'
    },
    oauth: {
        client_id: '@@client_id@@',
        redirect_uri: '@@host_frontend@@/callback',
        scope: 'read',
        checkTokenUri: '@@host_oauth2@@/api/ping',
        logoutUri: '@@host_oauth2@@/realms/@@oauth2_realm@@/protocol/openid-connect/logout',
        loginUri: '@@host_oauth2@@/realms/@@oauth2_realm@@/protocol/openid-connect/auth'
    },
    userSettings: {
        connectorProps: '@@host_main@@/connector-settings/connectors-props',
        connectorPropsTenantName: '@@host_main@@/connector-settings/getTenant/',
        allConnectorProps: '@@host_main@@/connector-settings/all-connectors-props',
        connectorPropsStructure: '@@host_main@@/connector-settings/connectors-props-structure',
    },
    carrierMapping: {
        carrier: '@@host_main@@/carrier-mapping/carrier/',
    },
    shippingService: {
        searchUri: '@@host_main@@/api/shipping-orders/rates',
        shipUri: '@@host_main@@/api/shipping-orders/ship',
        labelUri: '@@host_main@@/api/shipment/label',
        deleteUri: '@@host_main@@/api/shipment/delete',
        setCanceled: '@@host_main@@/api/shipment/cancel'
    },
    userService: {
        userData: '@@host_oauth2@@/api/me'
    },
    placesService: {
        autocompleteUrl: '@@host_main@@/api/addresses',
        placeUrl: '@@host_main@@/api/addresses'
    },
    orderService: {
        orderOverviewUrl: '@@host_main@@/orders/list?',
        orderOverview: '@@host_main@@/orders/list',
        orderDocument: '@@host_main@@/orders/document/',
        totalCount: '@@host_main@@/orders/totalCount',
        orderFilterUrl: '@@host_main@@/orders/filter?'
    },
    configurationService: {
        serviceOptions: '@@host_main@@/options'
    }
};
