import {Injectable, OnInit} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {OauthModel} from "./login/model/oauth";
import {KeycloakService} from "keycloak-angular";

@Injectable({
  providedIn: 'root'
})
export class PersistenceService implements OnInit {
  private auth_key: string = "auth";
  private authToken: BehaviorSubject<OauthModel> = new BehaviorSubject(null);

  constructor(private keycloak:KeycloakService) {
  }

  private static getStorage():Storage{
    return sessionStorage;
  }

  getToken(){
    return this.keycloak.getKeycloakInstance().token;
  }


  ngOnInit(): void {
  }

  hasAuth() {
    return this.keycloak.getKeycloakInstance().authenticated;
  }


  tokenExpired(): boolean {
    return this.keycloak.getKeycloakInstance().isTokenExpired()
  }
}
