<p-dropdown [options]="allConnectorPrefs"
            [(ngModel)]="selectedConnector"
            (onChange)="editDialog()"
            [showClear]="true"
            [filter]="true" filterBy="connectorName"
            optionLabel="connectorName"
            placeholder="Select a Provider">

    <ng-template pTemplate="selectedItem">
        <div *ngIf="selectedConnector!= undefined">
            <img [src]="findCarrierPictureForOverview(selectedConnector.connectorName)"
                 [alt]="selectedConnector.connectorName"/>
            <div>{{selectedConnector.connectorName}}</div>
        </div>
    </ng-template>

    <ng-template let-connector pTemplate="item">
        <div>
            <img [src]="findCarrierPictureForOverview(connector.connectorName)" [alt]="connector.connectorName"/>
            <div>{{connector.connectorName}}</div>
        </div>
    </ng-template>

</p-dropdown>


