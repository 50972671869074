<p-toast></p-toast>

<p-dialog header="Title" [(visible)]="delete">
    <p>
        Sie sind dabei diesen Provider aus ihrer Liste zu löschen. Gelöschte Provider müssen neu angelegt werden und
        alle Konfigurationsdaten gehen verloren.
        Möchten Sie fortfahren?
    </p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="deleteConnector()" label="Löschen" styleClass="p-button-text"></p-button>
        <p-button icon="pi pi-times" (click)="showDeleteDialog()" label="Abbrechen"
                  styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>

<div class="p-grid">
    <div class="p-col-12">
        <img [src]="findCarrierPictureForOverview(connector.carrier)" [alt]="connector.carrier" width="150px"
             height="50px"/><br>

        <div class="p-col-12"></div>
        <div class="p-col-12"></div>
        <div *ngFor="let preference of connector.preferences | keyvalue">

            <div style="text-align: start" class="p-col-12">

                <span style="display: inline-block" class="p-col-3">{{preference.key}}</span>

                <input [ngClass]="editable?'editable editInputField p-col-6':'notEditable editInputField p-col-6'" [readOnly]="!editable" type="text" id="{{preference.key}}"
                       (focusout)="updatePreferenceValues(preference)"
                       [(ngModel)]="preference.value">
            </div>


        </div>
        <div class="p-col-12"></div>
        <div class="p-col-12"></div>
        <div *ngIf="!editable" style="text-align: end">
            <p-button icon="pi pi-pencil" (onClick)="evaluateEditPrivileges()" label="Bearbeiten"></p-button>
            <p-button icon="pi pi-times" (onClick)="closeDialog('Abbrechen')" label="Abbrechen"></p-button>
        </div>
        <div class="p-col-12" *ngIf="editable" style="text-align: end">
            <p-button class="saveButton p-col" icon="pi pi-save" (onClick)="uploadConnector()"
                      label="Speichern"></p-button>
            <p-button class="deactivateButton p-col" *ngIf="connector.active" (onClick)="deactivateConnector()"
                      icon="pi pi-ban" label="Deaktivieren"></p-button>
            <p-button class="activateButton p-col" *ngIf="!connector.active" (onClick)="activateConnector()"
                      icon="pi pi-check-circle" label="Aktivieren"></p-button>
            <p-button icon="pi pi-trash" (onClick)="showDeleteDialog()" label="Löschen"></p-button>
            <p-button class="abortButton p-col" icon="pi pi-times" (onClick)="evaluateEditPrivileges()"
                      label="Abbrechen"></p-button>
        </div>
    </div>
</div>

