import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {Cancelshipment} from "../model/cancelshipment";
import {Documents} from "../model/documents";
import {Order} from "../model/order-model";

@Injectable({
  providedIn: 'root'
})
export class OrderOverviewService {


  orderUrl = environment.orderService.orderOverview;
  pageableOrderUrl = environment.orderService.orderOverviewUrl;
  filterOrderUrl = environment.orderService.orderFilterUrl;
  cancelShipment = environment.shippingService.deleteUri;
  setCanceled = environment.shippingService.setCanceled;
  totalCountofOrders = environment.orderService.totalCount;
  documents = environment.orderService.orderDocument;

  constructor(private http : HttpClient) { }



  getOrdersForOverview(page: number,rows: number) : Observable<any>{
    return this.http.get<any>(this.pageableOrderUrl+`page=${page}&size=${rows}`,{});
  }

  getSortedOrders(page, rows, sort, sortOrder): Observable<any>{
    console.log("Tabbelen Data: ", page, rows, sort, sortOrder);
    return this.http.get<any>(this.pageableOrderUrl+`page=${page}&size=${rows}&sort=${sort},${sortOrder}`, {});
  }


  cancelingShipment(shipmentId, connector): Observable<Cancelshipment>{
    return this.http.delete<Cancelshipment>(this.cancelShipment+ `/${shipmentId}/${connector}`);
  }

  getLabels(trackingNumber: string): Observable<Documents[]>{
    return this.http.get<Documents[]>(this.documents+`${trackingNumber}`);
  }


  getFilteredOrders(page, rows, field: string, value: string):  Observable<any>{
    return this.http.get<any>(this.filterOrderUrl+`page=${page}&size=${rows}&field=${field}&value=${value}`);
  }

  getFilteredAndSortedOrders(page, rows, sort, sortOrder, field: string, value: string):  Observable<any>{
    return this.http.get<any>(this.filterOrderUrl+`page=${page}&size=${rows}&sort=${sort},${sortOrder}&field=${field}&value=${value}`);
  }

  setShipmentAsCanceled(shipmentId, connector): Observable<Order>{
    return this.http.get<Order>(this.setCanceled+ `/${shipmentId}/${connector}`);
  }



}
