import {Component, OnDestroy, OnInit} from '@angular/core';
import {CLEAR, Store} from '../results/generic.store';
import {SEARCH, SearchStore} from './search.store';
import {MessageService} from 'primeng/api';
import {OrderStore} from '../order-overview/order.store';
import {OrderOverviewService} from '../order-overview/order-overview.service';
import {OrderService} from '../order-overview/order.service';

@Component({
    selector: 'logle-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy{
    showPickup = false;

    resultDisabled = true;
    contentDisabled = false;
    shippingDisabled = true;

    activeState: boolean[] = [true, false, false];
    activeIndex = 0;
    trackingNumber:string;

    searchResult: any;
    reset = false;

    constructor(private resultStore: Store,
                private searchStore: SearchStore,
                private messageService: MessageService,
                private orderStore:OrderStore,
                private orderOverviewService:OrderOverviewService,
                private orderService:OrderService) {
    }

    ngOnInit(): void {
        this.orderStore.item.subscribe(value => {
            if(value!=null)
            this.trackingNumber = value.trackingNumber;
        });
    }



    initState() {
        this.activeIndex = 0;
        this.resultDisabled = true;
        this.shippingDisabled = true;
        this.searchResult = [];
        this.updateState();
        this.messageService.clear();
    }

    startSearch(value: boolean) {

        this.resultDisabled = !value;
        if (this.resultDisabled) {
            this.activeIndex = 0;
        } else
            this.activeIndex = 1;
        this.searchResult = [];
        this.updateState();
    }

    carrierSelect() {
        this.activeIndex = 2;
        this.shippingDisabled = false;
        this.resultDisabled = false;
        this.updateState();
    }

    cancelOrder() {
        this.activeIndex = 0;
        this.resultStore.dispatch({type: CLEAR, data: []});
        this.searchStore.dispatch({type: SEARCH, data: []});
        this.shippingDisabled = true;
        this.resultDisabled = true
        this.reset = true;
        this.updateState();
        this.messageService.clear();
    }

    submitOrder() {
        this.resultStore.dispatch({type: CLEAR, data: []});
        this.searchStore.dispatch({type: SEARCH, data: []});
        this.shippingDisabled = true;
        this.resultDisabled = true
        this.reset = true;
        this.activeIndex = 0;
        this.updateState();
    }

    updateState() {
        if (this.activeIndex === 0) {
            this.activeState = [true, false, false];
        } else if (this.activeIndex === 1) {
            this.activeState = [false, true, false];

        } else if (this.activeIndex === 2) {
            this.activeState = [false, false, true];
        }
    }

    downloadLabel(){
        this.orderOverviewService.getLabels(this.trackingNumber).subscribe(value => {
            for(const label of value){
                this.orderService.createAndDownloadBlobFile(label.content, label.filename, this.trackingNumber);
            }
            this.trackingNumber = null;
        });
    }

    close() {
        this.trackingNumber=null;
    }

    ngOnDestroy(): void {
        this.orderStore.item.next(null);
    }
}
